import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { signOutAction } from "../redux/auth/authActions";
import { Navigate, Link } from "react-router-dom";
import SecondaryHeader from "../components/SecondaryHeader";
import Footer from "../components/Footer";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Tabs } from "antd";

import banner from "../assets/images/warranty-information.png";
import RedirectToSignin from "../components/RedirectToSignin";
import RedirectToHome from "../components/RedirectToHome";
import PrimaryMobileHeader from "../components/PrimaryMobileHeader";
import useScrollAnimation from "../components/useScrollAnimation";

const Career = () => {
  const dispatch = useDispatch();
  const elementsRef = useScrollAnimation();
  const [isMobile, setIsMobile] = useState(false);
  const { isAuthenticated } = useSelector((state) => state.auth);

  const handleSignOut = () => {
    dispatch(signOutAction());
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600); // Define the breakpoint for mobile
    };

    // Set initial value
    handleResize();

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const { TabPane } = Tabs;

  return (
    <div>
      <RedirectToSignin />
      <RedirectToHome />
      {!isMobile ? <SecondaryHeader /> : <PrimaryMobileHeader />}
      <div className="container">
        <div className="mt-4 pb-5">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-10">
              <h2
                className="mb-2 text-center"
                ref={(el) => (elementsRef.current[0] = el)}
                data-animation="animate__fadeInDown"
              >
                We have 0 open positions now!
              </h2>

              <div
                className="card border-0 bg-white roubded-10 mt-4 mb-4"
                ref={(el) => (elementsRef.current[1] = el)}
                data-animation="animate__fadeInUp"
              >
                <div className="card-body p-5">
                  <Tabs tabPosition="left">
                    <TabPane tab="All positions" key="1">
                      No positions are currently available. Please check back
                      later for updates!
                      {/* <div className='row'>
                                                <div className='col-12'>
                                                    <h4 className='mb-3'>Full-Stack Developers </h4>
                                                    <div className='mb-3'>
                                                        <button className='btn btn-outline-primary btn-sm rounded-pill me-2'>Tartu</button>
                                                        <button className='btn btn-outline-primary btn-sm rounded-pill me-2'>Full-time</button>
                                                    </div>
                                                    <p className='text-muted font-13'>Due to growing workload, we are looking for experienced and talented Full-Stack Developers to join our fast-paced Engineering team. You will work closely with Product, Design and Marketing to analyze, develop, debug, test, roll-out and support new and existing product features.</p>
                                                    <div className='text-end'>
                                                        <button type='button' className='btn btn-dark rounded-pill'>See positions <i class="bi bi-arrow-right-short"></i></button>
                                                    </div>
                                                </div>
                                                <div className='col-12'>
                                                    <h4 className='mb-3'>Application developer (react native)</h4>
                                                    <div className='mb-3'>
                                                        <button className='btn btn-outline-primary btn-sm rounded-pill me-2'>Tartu</button>
                                                        <button className='btn btn-outline-primary btn-sm rounded-pill me-2'>Full-time</button>
                                                    </div>
                                                    <p className='text-muted font-13'>Due to growing workload, we are looking for experienced and talented Full-Stack Developers to join our fast-paced Engineering team. You will work closely with Product, Design and Marketing to analyze, develop, debug, test, roll-out and support new and existing product features.</p>
                                                    <div className='text-end'>
                                                        <button type='button' className='btn btn-dark rounded-pill'>See positions <i class="bi bi-arrow-right-short"></i></button>
                                                    </div>
                                                </div>
                                                <div className='col-12'>
                                                    <h4 className='mb-3'>Senior Product designer</h4>
                                                    <div className='mb-3'>
                                                        <button className='btn btn-outline-primary btn-sm rounded-pill me-2'>Tartu</button>
                                                        <button className='btn btn-outline-primary btn-sm rounded-pill me-2'>Full-time</button>
                                                    </div>
                                                    <p className='text-muted font-13'>Due to growing workload, we are looking for experienced and talented Full-Stack Developers to join our fast-paced Engineering team. You will work closely with Product, Design and Marketing to analyze, develop, debug, test, roll-out and support new and existing product features.</p>
                                                    <div className='text-end'>
                                                        <button type='button' className='btn btn-dark rounded-pill'>See positions <i class="bi bi-arrow-right-short"></i></button>
                                                    </div>
                                                </div>
                                                <div className='col-12'>
                                                    <h4 className='mb-3'>Product Manager</h4>
                                                    <div className='mb-3'>
                                                        <button className='btn btn-outline-primary btn-sm rounded-pill me-2'>Tartu</button>
                                                        <button className='btn btn-outline-primary btn-sm rounded-pill me-2'>Full-time</button>
                                                    </div>
                                                    <p className='text-muted font-13'>Due to growing workload, we are looking for experienced and talented Full-Stack Developers to join our fast-paced Engineering team. You will work closely with Product, Design and Marketing to analyze, develop, debug, test, roll-out and support new and existing product features.</p>
                                                    <div className='text-end'>
                                                        <button type='button' className='btn btn-dark rounded-pill'>See positions <i class="bi bi-arrow-right-short"></i></button>
                                                    </div>
                                                </div>
                                                <div className='col-12'>
                                                    <h4 className='mb-3'>Product Owner</h4>
                                                    <div className='mb-3'>
                                                        <button className='btn btn-outline-primary btn-sm rounded-pill me-2'>Tartu</button>
                                                        <button className='btn btn-outline-primary btn-sm rounded-pill me-2'>Full-time</button>
                                                    </div>
                                                    <p className='text-muted font-13'>Due to growing workload, we are looking for experienced and talented Full-Stack Developers to join our fast-paced Engineering team. You will work closely with Product, Design and Marketing to analyze, develop, debug, test, roll-out and support new and existing product features.</p>
                                                    <div className='text-end'>
                                                        <button type='button' className='btn btn-dark rounded-pill'>See positions <i class="bi bi-arrow-right-short"></i></button>
                                                    </div>
                                                </div>
                                            </div> */}
                    </TabPane>
                    <TabPane tab="Enginering (0)" key="2">
                      {/* <div className='row'>
                                                <div className='col-12'>
                                                    <h4 className='mb-3'>Senior Product designer</h4>
                                                    <div className='mb-3'>
                                                        <button className='btn btn-outline-primary btn-sm rounded-pill me-2'>Tartu</button>
                                                        <button className='btn btn-outline-primary btn-sm rounded-pill me-2'>Full-time</button>
                                                    </div>
                                                    <p className='text-muted font-13'>Due to growing workload, we are looking for experienced and talented Full-Stack Developers to join our fast-paced Engineering team. You will work closely with Product, Design and Marketing to analyze, develop, debug, test, roll-out and support new and existing product features.</p>
                                                    <div className='text-end'>
                                                        <button type='button' className='btn btn-dark rounded-pill'>See positions <i class="bi bi-arrow-right-short"></i></button>
                                                    </div>
                                                </div>
                                                <div className='col-12'>
                                                    <h4 className='mb-3'>Product Manager</h4>
                                                    <div className='mb-3'>
                                                        <button className='btn btn-outline-primary btn-sm rounded-pill me-2'>Tartu</button>
                                                        <button className='btn btn-outline-primary btn-sm rounded-pill me-2'>Full-time</button>
                                                    </div>
                                                    <p className='text-muted font-13'>Due to growing workload, we are looking for experienced and talented Full-Stack Developers to join our fast-paced Engineering team. You will work closely with Product, Design and Marketing to analyze, develop, debug, test, roll-out and support new and existing product features.</p>
                                                    <div className='text-end'>
                                                        <button type='button' className='btn btn-dark rounded-pill'>See positions <i class="bi bi-arrow-right-short"></i></button>
                                                    </div>
                                                </div>
                                                <div className='col-12'>
                                                    <h4 className='mb-3'>Product Owner</h4>
                                                    <div className='mb-3'>
                                                        <button className='btn btn-outline-primary btn-sm rounded-pill me-2'>Tartu</button>
                                                        <button className='btn btn-outline-primary btn-sm rounded-pill me-2'>Full-time</button>
                                                    </div>
                                                    <p className='text-muted font-13'>Due to growing workload, we are looking for experienced and talented Full-Stack Developers to join our fast-paced Engineering team. You will work closely with Product, Design and Marketing to analyze, develop, debug, test, roll-out and support new and existing product features.</p>
                                                    <div className='text-end'>
                                                        <button type='button' className='btn btn-dark rounded-pill'>See positions <i class="bi bi-arrow-right-short"></i></button>
                                                    </div>
                                                </div>
                                                <div className='col-12'>
                                                    <h4 className='mb-3'>Full-Stack Developers </h4>
                                                    <div className='mb-3'>
                                                        <button className='btn btn-outline-primary btn-sm rounded-pill me-2'>Tartu</button>
                                                        <button className='btn btn-outline-primary btn-sm rounded-pill me-2'>Full-time</button>
                                                    </div>
                                                    <p className='text-muted font-13'>Due to growing workload, we are looking for experienced and talented Full-Stack Developers to join our fast-paced Engineering team. You will work closely with Product, Design and Marketing to analyze, develop, debug, test, roll-out and support new and existing product features.</p>
                                                    <div className='text-end'>
                                                        <button type='button' className='btn btn-dark rounded-pill'>See positions <i class="bi bi-arrow-right-short"></i></button>
                                                    </div>
                                                </div>
                                                <div className='col-12'>
                                                    <h4 className='mb-3'>Application developer (react native)</h4>
                                                    <div className='mb-3'>
                                                        <button className='btn btn-outline-primary btn-sm rounded-pill me-2'>Tartu</button>
                                                        <button className='btn btn-outline-primary btn-sm rounded-pill me-2'>Full-time</button>
                                                    </div>
                                                    <p className='text-muted font-13'>Due to growing workload, we are looking for experienced and talented Full-Stack Developers to join our fast-paced Engineering team. You will work closely with Product, Design and Marketing to analyze, develop, debug, test, roll-out and support new and existing product features.</p>
                                                    <div className='text-end'>
                                                        <button type='button' className='btn btn-dark rounded-pill'>See positions <i class="bi bi-arrow-right-short"></i></button>
                                                    </div>
                                                </div>
                                            </div> */}
                      No positions are currently available. Please check back
                      later for updates!
                    </TabPane>
                    {/* <TabPane tab="Product (3)" key="3">
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <h4 className='mb-3'>Product Manager</h4>
                                                    <div className='mb-3'>
                                                        <button className='btn btn-outline-primary btn-sm rounded-pill me-2'>Tartu</button>
                                                        <button className='btn btn-outline-primary btn-sm rounded-pill me-2'>Full-time</button>
                                                    </div>
                                                    <p className='text-muted font-13'>Due to growing workload, we are looking for experienced and talented Full-Stack Developers to join our fast-paced Engineering team. You will work closely with Product, Design and Marketing to analyze, develop, debug, test, roll-out and support new and existing product features.</p>
                                                    <div className='text-end'>
                                                        <button type='button' className='btn btn-dark rounded-pill'>See positions <i class="bi bi-arrow-right-short"></i></button>
                                                    </div>
                                                </div>
                                                <div className='col-12'>
                                                    <h4 className='mb-3'>Product Owner</h4>
                                                    <div className='mb-3'>
                                                        <button className='btn btn-outline-primary btn-sm rounded-pill me-2'>Tartu</button>
                                                        <button className='btn btn-outline-primary btn-sm rounded-pill me-2'>Full-time</button>
                                                    </div>
                                                    <p className='text-muted font-13'>Due to growing workload, we are looking for experienced and talented Full-Stack Developers to join our fast-paced Engineering team. You will work closely with Product, Design and Marketing to analyze, develop, debug, test, roll-out and support new and existing product features.</p>
                                                    <div className='text-end'>
                                                        <button type='button' className='btn btn-dark rounded-pill'>See positions <i class="bi bi-arrow-right-short"></i></button>
                                                    </div>
                                                </div>
                                                <div className='col-12'>
                                                    <h4 className='mb-3'>Full-Stack Developers </h4>
                                                    <div className='mb-3'>
                                                        <button className='btn btn-outline-primary btn-sm rounded-pill me-2'>Tartu</button>
                                                        <button className='btn btn-outline-primary btn-sm rounded-pill me-2'>Full-time</button>
                                                    </div>
                                                    <p className='text-muted font-13'>Due to growing workload, we are looking for experienced and talented Full-Stack Developers to join our fast-paced Engineering team. You will work closely with Product, Design and Marketing to analyze, develop, debug, test, roll-out and support new and existing product features.</p>
                                                    <div className='text-end'>
                                                        <button type='button' className='btn btn-dark rounded-pill'>See positions <i class="bi bi-arrow-right-short"></i></button>
                                                    </div>
                                                </div>
                                                <div className='col-12'>
                                                    <h4 className='mb-3'>Application developer (react native)</h4>
                                                    <div className='mb-3'>
                                                        <button className='btn btn-outline-primary btn-sm rounded-pill me-2'>Tartu</button>
                                                        <button className='btn btn-outline-primary btn-sm rounded-pill me-2'>Full-time</button>
                                                    </div>
                                                    <p className='text-muted font-13'>Due to growing workload, we are looking for experienced and talented Full-Stack Developers to join our fast-paced Engineering team. You will work closely with Product, Design and Marketing to analyze, develop, debug, test, roll-out and support new and existing product features.</p>
                                                    <div className='text-end'>
                                                        <button type='button' className='btn btn-dark rounded-pill'>See positions <i class="bi bi-arrow-right-short"></i></button>
                                                    </div>
                                                </div>
                                                <div className='col-12'>
                                                    <h4 className='mb-3'>Senior Product designer</h4>
                                                    <div className='mb-3'>
                                                        <button className='btn btn-outline-primary btn-sm rounded-pill me-2'>Tartu</button>
                                                        <button className='btn btn-outline-primary btn-sm rounded-pill me-2'>Full-time</button>
                                                    </div>
                                                    <p className='text-muted font-13'>Due to growing workload, we are looking for experienced and talented Full-Stack Developers to join our fast-paced Engineering team. You will work closely with Product, Design and Marketing to analyze, develop, debug, test, roll-out and support new and existing product features.</p>
                                                    <div className='text-end'>
                                                        <button type='button' className='btn btn-dark rounded-pill'>See positions <i class="bi bi-arrow-right-short"></i></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </TabPane> */}
                    <TabPane tab="Design (0)" key="4">
                      No positions are currently available. Please check back
                      later for updates!
                    </TabPane>
                    <TabPane tab="Operation (0)" key="5">
                      No positions are currently available. Please check back
                      later for updates!
                    </TabPane>
                    <TabPane tab="Marketing (0)" key="6">
                      No positions are currently available. Please check back
                      later for updates!
                    </TabPane>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Career;
