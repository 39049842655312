import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { signOutAction } from "../redux/auth/authActions";
import { Navigate } from "react-router-dom";
import PrimaryHeader from "../components/PrimaryHeader";
import Footer from "../components/Footer";
import Services from "../components/Services";
import Brands from "../components/Brands";
import Testimonials from "../components/Testimonials";
import RedirectToHome from "../components/RedirectToHome";
import RedirectToSignin from "../components/RedirectToSignin";
import PrimaryMobileHeader from "../components/PrimaryMobileHeader";
import HomeFilterBanner from "../components/HomeFilterBanner";
import "../App.css";
import InstagramFeed from "../components/InstagramFeed";
import NewArrivals from "../components/NewArrivals";
import FloatingButton from "../components/FloatingButton";
import ChatButton from "../components/FloatingChatButton";
const HomePage = () => {
  const dispatch = useDispatch();
  const [isMobile, setIsMobile] = useState(false);
  const [isMobileheader, setIsMobileHeader] = useState(false);
  const { isAuthenticated } = useSelector((state) => state.auth);

  const handleSignOut = () => {
    dispatch(signOutAction());
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
      setIsMobileHeader(window.innerWidth <= 1400); // Define the breakpoint for mobile
    };

    // Set initial value
    handleResize();

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="bg-white">
      <RedirectToSignin />
      <RedirectToHome />
      {!isMobile ? <PrimaryHeader /> : <PrimaryMobileHeader />}

      {isMobile && <HomeFilterBanner />}

      <div className="banner"></div>
      <div className="container">
        <NewArrivals />
        {/* Services */}
        <Services />
        {/* Brands Models */}
        <Brands />
        {/* Testimonials */}
        <Testimonials />
        <InstagramFeed />
        {/* <FloatingButton/> */}
      </div>
      <Footer />
    </div>
  );
};

export default HomePage;
