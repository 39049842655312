import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Result } from "antd";
import { signOutAction } from "../redux/auth/authActions";
import { Navigate, useNavigate } from "react-router-dom";
import logo from "../assets/images/blue-logo.png"; // Import the image file
import checked from "../assets/images/checked.png";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
const ComingSoon = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isAuthenticated } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const handleSignOut = () => {
    dispatch(signOutAction());
  };
  const handleShareLink = () => {
    const referralLink = "http://localhost:3000/individual-register"; // Replace with your actual referral link
    if (navigator.share) {
      navigator
        .share({
          title: "Join Us",
          text: "Join this amazing platform and earn rewards!",
          url: referralLink,
        })
        .then(() => {
          console.log("Referral link shared successfully");
        })
        .catch((error) => {
          console.error("Error sharing referral link:", error);
        });
    } else {
      // Fallback if Web Share API is not supported
      navigator.clipboard.writeText(referralLink).then(() => {
        alert("Referral link copied to clipboard!");
      });
    }
  };
  useEffect(() => {
    const detectedLanguage =
      localStorage.getItem("selectedLanguage") ||
      navigator.language.split("-")[0] ||
      "en";
    i18n.changeLanguage(detectedLanguage);
    document.documentElement.lang = detectedLanguage;
    // const timer = setTimeout(() => {
    //   navigate("/signin");
    // }, 3000);

    // return () => clearTimeout(timer);
  }, [navigate]);
  if (!isAuthenticated) {
    return <Navigate to="/signin" />;
  }

  return (
    <div className="login-bg vh-100">
      <div className="container d-flex justify-content-center align-items-center pt-5 pb-5">
        <div className="login-register">
          <div className="card text-center bg-white rounded-20 mt-5 mb-5">
            <div className="card-body p-5">
              <img src={logo} alt="Logo" width="200" className="" />
              {/* <div><img src={checked} alt='Icon' width='80' className='mb-3' /></div> */}
              <Result
                status="success"
                title={t("register_success_msg")}
                subTitle={
                  <div>
                    <p>
                      {t(
                        "We're thrilled to have you onboard. If you know someone who would also enjoy being part of our community, share the link below and invite them to join. You'll earn exclusive rewards with every successful referral!"
                      )}
                    </p>
                    <a onClick={handleShareLink}>
                      {t("Click here to share your referral link!")}
                    </a>
                    <p>{t("Thank you for being part of our journey!")}</p>
                  </div>
                }
                extra={[
                  <Button
                    type="primary"
                    key="console"
                    onClick={() => navigate("/signin")}
                  >
                    {t("login")}
                  </Button>,
                ]}
              />

              {/* 
                            <p className='text-muted'>Your registration is currently being reviewed.</p> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComingSoon;
