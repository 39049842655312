import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCarsByBrand } from "../redux/auth/authActions";
import { useLocation, useNavigate, Navigate } from "react-router-dom";
import ProductGrid from "../components/ProductGrid";
import CustomPagination from "../components/CustomPagination"; // Import the custom component
import Footer from "../components/Footer";
import SecondaryHeader from "../components/SecondaryHeader";
import PrimaryMobileHeader from "../components/PrimaryMobileHeader";
import RedirectToHome from "../components/RedirectToHome";
import RedirectToSignin from "../components/RedirectToSignin";
import { RotatingLines } from "react-loader-spinner";
import loaderLogo from "../assets/images/CM LOGO DARK BLUE.png";
import { API_URL } from "../api/authApi";
import { Button } from "react-bootstrap";
import { BsGrid, BsList } from "react-icons/bs";
import BgForBrandModels from "../components/BgForBrandModels";
import bluelogo from "../assets/images/blue-logo.png";
const BrandModels = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [cars, setCars] = useState([]);
  const api_url = API_URL.replace("/api", "");
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(null);
  const { isAuthenticated } = useSelector((state) => state?.auth);
  const searchParams = new URLSearchParams(location?.search);
  const brand_slug = searchParams?.get("make");
  const model_slug = searchParams?.get("model");
  const interior_color = searchParams?.get("interiorColor");
  const exterior_color = searchParams?.get("exteriorColor");
  const steering = searchParams?.get("steering");
  const fuelType = searchParams?.get("fuelType");

  const [only_fuel_type, setOnlyFuelType] = useState(false);
  console.log(
    "brand_slug && model_slug === null",
    brand_slug === null && model_slug === null
  );

  // if (brand_slug && model_slug === null) {
  //   setOnlyFuelType(true);
  // }
  // setCurrentPage(searchParams?.get("page"));
  const vin = searchParams?.get("vin");
  const [logo, setLogo] = useState("");
  const [brandName, setBrandName] = useState("");
  const [backgroundLogo, setBackgroundLogo] = useState("");
  const [isLuxury, setIsLuxury] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const fetchCars = useCallback(
    async (page = 1, limit = 50, order_by = "new") => {
      setIsLoading(true);
      try {
        const response = await dispatch(
          getCarsByBrand({
            page,
            make: brand_slug,
            model: model_slug,
            vin: vin,
            int_color: interior_color,
            ext_color: exterior_color,
            steering: steering,
            fuel_type: fuelType,
            limit,
            order_by,
          })
        );
        setIsLoading(false);
        setCars(response?.data?.result?.data || []);
        setLogo(`${api_url}/${response?.data?.car_make?.logo?.path}`);
        setBackgroundLogo(
          `${api_url}/${response?.data?.car_make?.background_logo?.path}`
        );
        console.log(
          "lllllll:",
          `${api_url}/${response?.data?.car_make?.background_logo?.path}`
        );
        setIsLuxury(response?.data?.car_make?.is_luxury || false);
        setBrandName(response?.data?.car_make?.name || "");
        setCurrentPage(response?.data?.result?.current_page || page);
        setLastPage(response?.data?.result?.last_page);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching cars:", error);
        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    },
    [
      // dispatch,
      brand_slug,
      model_slug,
      interior_color,
      exterior_color,
      vin,
      steering,
      fuelType
    ]
  );
  useEffect(() => {
    const pageFromQuery = parseInt(searchParams?.get("page"), 10) || 1; // Default to page 1 if no query param
    if (pageFromQuery !== currentPage) {
      setCurrentPage(pageFromQuery);
    }
  }, [searchParams, currentPage]);
  useEffect(() => {
    const pageFromQuery = parseInt(searchParams?.get("page"), 10) || 1; // Default to page 1 if no query param
    if (pageFromQuery !== currentPage) {
      setCurrentPage(pageFromQuery);
    }
    if (brand_slug) {
      setCars([]);
      setCurrentPage(1);
      setLastPage(null);
      if (pageFromQuery) {
        fetchCars(pageFromQuery);
      } else {
        fetchCars(1);
      }
    }
  }, [brand_slug, model_slug,vin,exterior_color,interior_color,steering,fuelType]);

  const handlePageChange = (page) => {
    if (page >= 1 && page <= lastPage) {
      setCurrentPage(page);
      const searchParams = new URLSearchParams(location.search);
      searchParams.set("page", page); // Add or update the "page" parameter
      navigate(`${location.pathname}?${searchParams.toString()}`);
      fetchCars(page);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600); // Define the breakpoint for mobile
    };

    // Set initial value
    handleResize();

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/signin"); // Use navigate inside useEffect for redirection
    }
    let updated = false;

    // Get query parameters
    const pageFromQuery = parseInt(searchParams.get("page"), 10);
    const fuelFromQuery = parseInt(searchParams.get("fuelType"));

    // Set default for page if missing
    if (!pageFromQuery) {
      searchParams.set("page", "1");
      setCurrentPage(1);
      updated = true;
    } else if (pageFromQuery !== currentPage) {
      setCurrentPage(pageFromQuery);
    }
    // if(fuelFromQuery){
    //   setFuelTy
    // }
    console.log("isidfbdsjifbdsubdsouc:", updated, searchParams);

    // Update the URL if changes were made
    if (updated) {
      console.log("path:", `${location.pathname}?${searchParams.toString()}`);
      // fetchCars();
      navigate(`${location.pathname}?${searchParams.toString()}`, {
        replace: true,
      });
    } 
  }, [
    location,
    navigate,
    currentPage,
    brand_slug,
    model_slug,
    interior_color,
    exterior_color,
    vin,
    fuelType
  ]);

  useEffect(() => {
    fetchCars();
  }, [fuelType]);
  const capitalizeFirstLetter = (string) => {
    return string?.charAt(0)?.toUpperCase() + string?.slice(1)?.toLowerCase();
  }

  // Combined fuel type string with 'Cars', capitalized
  const displayFuelType = capitalizeFirstLetter(fuelType) + ' Cars';

  if (!isAuthenticated) {
    return <Navigate to="/signin" />;
  }

  return (
    <>
      <div className="">
        {!isLoading && (
          <BgForBrandModels url={backgroundLogo} isBlack={isLuxury} />
        )}
        <RedirectToSignin />
        <RedirectToHome />
        {!isMobile ? (
          <SecondaryHeader isBlack={isLuxury} />
        ) : (
          // <></>
          <PrimaryMobileHeader isBlack={isLuxury} />
        )}
        <div className="container">
          {isLoading ? (
            <div className="d-flex justify-content-center align-items-center vh-100">
              <RotatingLines
                strokeColor="#000740"
                strokeWidth="4"
                animationDuration="0.75"
                width="96"
                visible={true}
              />
              <img
                src={loaderLogo}
                style={{ width: "250px", height: "250px" }}
                alt="Loading"
              />
            </div>
          ) : (
            <>
              <div className="text-center mb-2">
                {brand_slug === null && model_slug === null ? (
                  <img
                    src={bluelogo}
                    alt="Logo"
                    className="img-fluid"
                    style={{
                      width: "180px",
                      height: "150px",
                      objectFit: "contain",
                    }}
                  />
                ) : (
                  <img
                    src={logo}
                    alt="Logo"
                    className="img-fluid"
                    style={{
                      width: "180px",
                      height: "150px",
                      objectFit: "contain",
                    }}
                  />
                )}
                <h4
                  className="text-uppercase fw-bold mt-1"
                  style={{
                    ...(isLuxury && {
                      color: "white",
                    }), // Style for companyLogo // Style for alternateLogo
                  }}
                >
                   {brand_slug === null && model_slug === null ? displayFuelType : brandName}
                  {/* {brandName} */}
                </h4>
              </div>
              <ProductGrid
                products={cars}
                car_make_slug={brand_slug}
                car_model_slug={model_slug}
                interiorColor={interior_color}
                exteriorColor={exterior_color}
                steeringProp={steering}
                vin={vin}
                fetchCars={fetchCars}
                isLuxury={isLuxury}
              />
              <div className="d-flex justify-content-end mt-2">
                <CustomPagination
                  currentPage={currentPage}
                  lastPage={lastPage}
                  onPageChange={handlePageChange}
                  isLuxury={isLuxury}
                />
              </div>
            </>
          )}
        </div>
        {!isLoading && <Footer isBlack={isLuxury} />}
      </div>
    </>
  );
};

export default BrandModels;
