import "../components/Testimonials.css";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import b1 from "../assets/images/b1.png";
import b2 from "../assets/images/b2.png";
import b3 from "../assets/images/b3.png";
import b4 from "../assets/images/b4.png";
import b5 from "../assets/images/b5.png";
import b6 from "../assets/images/b6.png";
import comma from "../assets/images/comma.png";
// import userIcon from "../assets/images/user-ico.png";
import useScrollAnimation from "./useScrollAnimation";
import TestimonialCard from "./TestimonialCard";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import carousel styles
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
import { useDispatch, useSelector } from "react-redux";
import { FaStar, FaRegStar, FaStarHalfAlt } from "react-icons/fa"; // For star ratings
import userIcon from "../assets/images/user-ico.png"; // Replace with actual image path
import googleIcon from "../assets/images/google-icon.png";
import p1 from "../assets/images/review-profile/1.png";
import p2 from "../assets/images/review-profile/2.png";
import p3 from "../assets/images/review-profile/3.png";
import p4 from "../assets/images/review-profile/4.png";
import p5 from "../assets/images/review-profile/5.png";
import p6 from "../assets/images/review-profile/6.png";
const Testimonials = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const elementsRef = useScrollAnimation();
  const testimonials = [1, 2, 3, 4];
  const reviews = [
    {
      id: 1,
      name: "Heseen Khan",
      date: "October 15, 2024",
      rating: 5,
      // image: "../assets/images/review-profile/1.png",
      image: p1,
      review:
        "Cruise Motors is a fantastic company with fair prices and reliable service. Thank you for your commitment!",
    },
    {
      id: 2,
      name: "Mohammad Nasrullah",
      date: "September 8, 2024",
      rating: 5,
      image: p2,
      review:
        "1 had a wonderful experience working with Miss Jessica at Cruise Motors. Her commitment to customer satisfaction is outstanding, and l'm so grateful for her dedication and support. Ihighly recommend this company for quality vehicles and excellent service. My sincere thanks to Jessica for her exceptional work. I recently purchased a Toyota Lanc a Cruiser Prado VX for export to Cameroon",
    },
    {
      id: 3,
      name: "M. Asamoah",
      date: "August 20, 2023",
      rating: 5,
      image: p3,
      review:
        "An excellent car company with an extensive selection of models. I want to thank the team for helping me through the entire purchase process of the Toyota Land Cruiser Twin Turbo for Nigeria and for their patience and willingness to address all my questions",
    },
    {
      id: 4,
      name: "Sherin Omar",
      date: "July 12, 2023",
      rating: 5,
      image: p4,
      review:
        " I'm truly grateful for the thoughtful way you handled my recent purchase of three vehicles from your Dubai showroom. It was my first time visiting Dubai, and your team made the process seamless. Your willingness to go above and beyond to help with every detail was remarkable, making this major purchase smooth and efficient. Thank you.. ",
    },
    {
      id: 5,
      name: "Athal Blailo",
      date: "June 30, 2023",
      image: p5,
      rating: 5,
      review:
        "My recent car purchase at Cruise Motors was an exceptional experience. From the moment we entered, we were treated with respect and professionalism. As new car buyers, we felt assured by his straightforward approach. Cruise Motors truly stands out! ",
    },
    {
      id: 6,
      name: "Hazem Jb",
      date: "May 25, 2023",
      image: p6,
      rating: 5,
      review:
        "I found exactly what I was looking for at Cruise Motors, and lm grateful to the team, especially sales representative Miss Jessica. The prices are reasonable, service is quick, and, above all, they are honest people. Thanks, everyone!",
    },
  ];
  useEffect(() => {
    // Detect and set the default language based on the browser or localStorage
    const detectedLanguage =
      localStorage.getItem("selectedLanguage") ||
      navigator.language.split("-")[0] ||
      "en";
    i18n.changeLanguage(detectedLanguage);
    document.documentElement.lang = detectedLanguage;
  }, [dispatch]);
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600); // Define the breakpoint for mobile
    };

    // Set initial value
    handleResize();

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <div
      className="carouselCol pt-3 "
      ref={(el) => (elementsRef.current[1] = el)}
      data-animation="animate__fadeInUp"
    >
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h4 className="text-capitalize">{t("customer_say")}</h4>
        {/* <Link to='/' className='text-dark text-decoration-none'>Show All Testimonials <i class="bi bi-arrow-right ms-2"></i></Link> */}
      </div>
      {/* <div className="row g-3">
        {testimonials.map((testimonial, index) => (
          <div className="col-12 col-sm-4 col-md-3 col-lg-3 mt-4" key={index}>
            <TestimonialCard />
          </div>
        ))}
      </div> */}

      {!isMobile ? (
        <Carousel
          className="custom-carousel-brand"
          emulateTouch={true}
          preventMovementUntilSwipeScrollTolerance={true} // Ignore accidental swipes
          swipeScrollTolerance={5}
          showThumbs={false}
          autoPlay={false}
          infiniteLoop={false}
          // showArrows={true}
          showStatus={false}
          renderArrowPrev={(onClickHandler, hasPrev) =>
            hasPrev && (
              <button
                type="button"
                onClick={onClickHandler}
                className="custom-prev-arrow-brand"
              >
                <i className="bi bi-chevron-left custom-carousel-icon"></i>
              </button>
            )
          }
          renderArrowNext={(onClickHandler, hasNext) =>
            hasNext && (
              <button
                type="button"
                onClick={onClickHandler}
                className="custom-next-arrow-brand"
              >
                <i className="bi bi-chevron-right custom-carousel-icon"></i>
              </button>
            )
          }
        >
          {/* Iterate through the reviews in chunks of 3 */}
          {reviews
            ?.reduce((resultArray, review, index) => {
              const chunkIndex = Math.floor(index / 3);

              if (!resultArray[chunkIndex]) {
                resultArray[chunkIndex] = []; // start a new chunk
              }

              resultArray[chunkIndex].push(review);

              return resultArray;
            }, [])
            .map((reviewChunk, chunkIndex) => (
              <div className="review-carousel" key={chunkIndex}>
                <div className="row g-3">
                  {reviewChunk.map((review) => (
                    <div
                      className="col-12 col-sm-6 col-lg-4 mb-4"
                      key={review.id}
                    >
                      <div className="text-decoration-none text-dark" >
                        <div
                          className="border rounded-10 text-start bg-white p-3 shadow-sm review-card highlightHover"
                          style={{ height: "18rem" }}
                        >
                          {/* Header: User Icon, Name, Date */}
                          <div className="d-flex justify-content-between align-items-center ">
                            <div className="d-flex align-items-center">
                              <div className="me-2">
                                {review.image ? (
                                  <img
                                    className="testimonials-profile-image "
                                    src={review.image}
                                    alt={review.name}
                                  />
                                ) : (
                                  <div
                                    className="rounded-pill me-2 text-white user-icon d-flex align-items-center justify-content-center"
                                    style={{
                                      width: "40px",
                                      height: "40px",
                                      backgroundColor: "#000740",
                                    }}
                                  >
                                    {review.name.charAt(0)}
                                  </div>
                                )}
                              </div>
                              <div>
                                <h6 className="m-0">{review.name}</h6>
                                <p className="text-muted font-13 m-0">
                                  {review.date}
                                </p>
                              </div>
                            </div>
                            <img
                              src={googleIcon}
                              alt="Google"
                              style={{ width: "30px", height: "30px" }}
                            />
                          </div>

                          {/* Rating Section */}
                          <div className="d-flex align-items-center">
                            <span className="badge bg-warning text-dark me-2">
                              {review.rating}
                            </span>
                            <div className="d-flex">
                              {[...Array(5)].map((star, i) => (
                                <i
                                  key={i}
                                  className={`bi ${
                                    i < Math.floor(review.rating)
                                      ? "bi-star-fill"
                                      : i < review.rating
                                      ? "bi-star-half"
                                      : "bi-star"
                                  } text-warning`}
                                ></i>
                              ))}
                            </div>
                          </div>

                          {/* Review Text */}
                          <div className="review-text">
                            <p className="text-muted font-14">
                              {review.review}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
        </Carousel>
      ) : (
        <>
                 {reviews
            ?.reduce((resultArray, review, index) => {
              const chunkIndex = Math.floor(index / 3);

              if (!resultArray[chunkIndex]) {
                resultArray[chunkIndex] = []; // start a new chunk
              }

              resultArray[chunkIndex].push(review);

              return resultArray;
            }, [])
            .map((reviewChunk, chunkIndex) => (
              <div className="review-carousel" key={chunkIndex}>
                <div className="row g-3">
                  {reviewChunk.map((review) => (
                    <div
                      className="col-12 col-sm-6 col-lg-4 mb-4"
                      key={review.id}
                    >
                      <Link className="text-decoration-none text-dark">
                        <div
                          className="border rounded-10 text-start bg-white p-3 shadow-sm review-card"
                          style={{ height: "18rem" }}
                        >
                          {/* Header: User Icon, Name, Date */}
                          <div className="d-flex justify-content-between align-items-center ">
                            <div className="d-flex align-items-center">
                              <div className="me-2">
                                {review.image ? (
                                  <img
                                    className="testimonials-profile-image "
                                    src={review.image}
                                    alt={review.name}
                                  />
                                ) : (
                                  <div
                                    className="rounded-pill me-2 text-white user-icon d-flex align-items-center justify-content-center"
                                    style={{
                                      width: "40px",
                                      height: "40px",
                                      backgroundColor: "#000740",
                                    }}
                                  >
                                    {review.name.charAt(0)}
                                  </div>
                                )}
                              </div>
                              <div>
                                <h6 className="m-0">{review.name}</h6>
                                <p className="text-muted font-13 m-0">
                                  {review.date}
                                </p>
                              </div>
                            </div>
                            <img
                              src={googleIcon}
                              alt="Google"
                              style={{ width: "30px", height: "30px" }}
                            />
                          </div>

                          {/* Rating Section */}
                          <div className="d-flex align-items-center">
                            <span className="badge bg-warning text-dark me-2">
                              {review.rating}
                            </span>
                            <div className="d-flex">
                              {[...Array(5)].map((star, i) => (
                                <i
                                  key={i}
                                  className={`bi ${
                                    i < Math.floor(review.rating)
                                      ? "bi-star-fill"
                                      : i < review.rating
                                      ? "bi-star-half"
                                      : "bi-star"
                                  } text-warning`}
                                ></i>
                              ))}
                            </div>
                          </div>

                          {/* Review Text */}
                          <div className="review-text">
                            <p className="text-muted font-14">
                              {review.review}
                            </p>
                          </div>
                        </div>
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
            ))}</>
      )}
    </div>
  );
};

export default Testimonials;
