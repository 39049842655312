// import './ServicePage.css';
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { signOutAction } from "../redux/auth/authActions";
import { Navigate, Link } from "react-router-dom";
import SecondaryHeader from "../components/SecondaryHeader";
import Footer from "../components/Footer";
import banner from "../assets/images/logistics.png";
import log1 from "../assets/images/log1.png";
import log2 from "../assets/images/log2.png";
import RedirectToHome from "../components/RedirectToHome";
import RedirectToSignin from "../components/RedirectToSignin";
import PrimaryMobileHeader from "../components/PrimaryMobileHeader";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
import "./Logistics.css";
const Logistics = () => {
  const dispatch = useDispatch();
  const [isMobile, setIsMobile] = useState(false);
  const { isAuthenticated } = useSelector((state) => state.auth);
  const { t } = useTranslation();
  const handleSignOut = () => {
    dispatch(signOutAction());
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600); // Define the breakpoint for mobile
    };
    const detectedLanguage =
      localStorage.getItem("selectedLanguage") ||
      navigator.language.split("-")[0] ||
      "en";
    i18n.changeLanguage(detectedLanguage);
    document.documentElement.lang = detectedLanguage;
    // Set initial value
    handleResize();

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="bg-white">
      <RedirectToSignin />
      <RedirectToHome />
      {!isMobile ? <SecondaryHeader /> : <PrimaryMobileHeader />}
      <div className="container">
        <div className="servicesPageList mt-2 pb-2">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-7">
              <img src={banner} alt="Banner" className="img-fluid mb-5" />
              <h2 className="mb-4 text-center">{t("logistic")}</h2>
              <div className="row g-4 mb-2 justify-content-center">
                <div className="col-12 col-sm-6">
                  <div className="card rounded-15 border-0">
                    <Link
                      to="/request-for-quotation"
                      className="text-decoration-none text-dark"
                    >
                      <div className="card-body p-0">
                        <div className="imgCol  position-relative">
                          <img
                            src={log1}
                            className="img-fluid rounded-10"
                            alt="Service 6"
                          />
                          <span className="short-btn rounded-pill bg-white position-absolute rounded-pill bg-white position-absolute p-2 ps-3 pe-3">
                            {t("request_for_quotation")}
                          </span>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="col-12 col-sm-6">
                  <div className="card rounded-15 border-0">
                    <Link
                      to="/documents"
                      className="text-decoration-none text-dark"
                    >
                      <div className="card-body p-0">
                        <div className="imgCol position-relative">
                          <img
                            src={log2}
                            className="img-fluid rounded-10"
                            alt="Service 6"
                          />
                          <span className="short-btn rounded-pill bg-white position-absolute rounded-pill bg-white position-absolute p-2 ps-3 pe-3">
                            {t("doc_cert")}
                          </span>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>

              <div class="container-text mt-3">
                {/* <!-- Container for the fade effect --> */}
                <div class="fade-container">
                  <div class="scrollable-section">
                    <div class="scrollable-content ms-2">
                      <div class="row">
                        {/* <!-- Shipping Methods --> */}
                        <div class="col-12 pb-3">
                          <h3 class="fw-bold">Shipping Methods</h3>
                          <div class="d-flex align-items-center mb-4">
                            <i class="bi bi-building fs-1 me-3"></i>
                            <div>
                              <h5 class="fw-bold">Container Shipping</h5>
                              <p>
                                Use a container to keep your car safe while it
                                travels. You can share a container with others
                                or use one just for your car.
                              </p>
                            </div>
                          </div>
                          <div class="d-flex align-items-center mb-4">
                            <i class="bi bi-truck fs-1 me-3"></i>
                            <div>
                              <h5 class="fw-bold">
                                Roll-On Roll-Off (RoRo) Shipping
                              </h5>
                              <p>
                                This is a cheaper and faster way to ship. Your
                                car is driven onto a large ship and off again at
                                the destination. It's simple and effective.
                              </p>
                            </div>
                          </div>
                          <div class="d-flex align-items-center mb-4">
                            <i class="bi bi-airplane fs-1 me-3"></i>
                            <div>
                              <h5 class="fw-bold">Air Freight</h5>
                              <p>
                                The quickest but most expensive way to send your
                                car. Best for very expensive or classic cars.
                              </p>
                            </div>
                          </div>
                        </div>

                        {/* <!-- What You Need Section --> */}
                        <div class="col-12">
                          <h3 class="fw-bold">What You Need</h3>
                          <div class="d-flex align-items-center mb-4">
                            <i class="bi bi-file-earmark-text fs-1 me-3"></i>
                            <div>
                              <h5 class="fw-bold">Important Paperwork</h5>
                              <p>
                                Make sure you have your car’s title and
                                registration, and your photo ID ready. Some
                                countries might ask for more papers.
                              </p>
                            </div>
                          </div>
                          <div class="d-flex align-items-center mb-4">
                            <i class="bi bi-check-circle fs-1 me-3"></i>
                            <div>
                              <h5 class="fw-bold">Get Your Car Ready</h5>
                              <p>
                                Your car should be clean and in good running
                                condition. It should have only a little gas in
                                it for safety.
                              </p>
                            </div>
                          </div>
                          <div class="d-flex align-items-center mb-4">
                            <i class="bi bi-currency-dollar fs-1 me-3"></i>
                            <div>
                              <h5 class="fw-bold">Costs</h5>
                              <p>
                                The price to ship your car depends on how big it
                                is, how far it’s going, and how you choose to
                                ship it. Prepare to pay between $1,000 and
                                $5,000.
                              </p>
                            </div>
                          </div>
                        </div>

                        {/* <!-- Guide Section --> */}
                        <div class="col-12">
                          <h3 class="fw-bold">Guide</h3>
                          <div class="mb-4">
                            <h5 class="fw-bold">EXW - Ex Works</h5>
                            <p>
                              EXW, or "Ex Works," means the seller provides the
                              car at their place of business, and the buyer is
                              responsible for all costs and risks involved in
                              moving the car from there. This is the most basic
                              shipping term, requiring the buyer to handle all
                              transportation, export duties, and risks once the
                              car is ready for collection at the seller's
                              location.
                            </p>
                          </div>
                          <div class="mb-4">
                            <h5 class="fw-bold">CNF - Cost and Freight</h5>
                            <p>
                              CNF, or "Cost and Freight," involves the seller
                              paying for the cost of getting the car to the
                              destination port. However, once the car is on the
                              ship, the buyer takes on the responsibility. This
                              means the buyer needs to cover insurance and the
                              cost of transporting the car from the destination
                              port to its final location.
                            </p>
                          </div>
                          <div class="mb-4">
                            <h5 class="fw-bold">FOB - Free On Board</h5>
                            <p>
                              FOB, or "Free on Board," means the seller covers
                              all costs and risks until the car is loaded onto
                              the ship. They are responsible for local logistics
                              and costs, including transport to the port and
                              loading. Once the car is on board, the buyer
                              becomes responsible for all further costs and
                              risks.
                            </p>
                          </div>
                          <div class="mb-4">
                            <h5 class="fw-bold">
                              CIF - Cost, Insurance, and Freight
                            </h5>
                            <p>
                              CIF, or "Cost, Insurance, and Freight," is similar
                              to CNF but with one key addition: the seller also
                              pays for insurance covering the risk of loss or
                              damage to the car while it is in transit to the
                              destination port. This term provides greater
                              security for the buyer, as they are covered during
                              the sea transit.
                            </p>
                          </div>
                          <div class="mb-4">
                            <h5 class="fw-bold">FCA - Free Carrier</h5>
                            <p>
                              FCA, or "Free Carrier," involves the seller
                              handing over the car into the custody of the first
                              carrier at the specified location, which is often
                              the port of loading. The buyer takes over all
                              responsibilities from this point, including sea
                              freight, insurance, and further transportation
                              costs.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Logistics;
