import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { signOutAction } from '../redux/auth/authActions';
import { Navigate, Link } from 'react-router-dom';
import SecondaryHeader from '../components/SecondaryHeader';
import Footer from '../components/Footer';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import banner from '../assets/images/warranty-information.png';
import RedirectToSignin from '../components/RedirectToSignin';
import RedirectToHome from '../components/RedirectToHome';
import PrimaryMobileHeader from '../components/PrimaryMobileHeader';

import placeHolder from "../assets/images/noname.webp"

import t1 from '../assets/images/t1.png'; 
import t2 from '../assets/images/t2.png'; 
import t3 from '../assets/images/t3.png'; 
import t4 from '../assets/images/t4.png'; 
import Brands from '../components/Brands';
import useScrollAnimation from '../components/useScrollAnimation';

const OurTeam = () => {
    const dispatch = useDispatch();
    const elementsRef = useScrollAnimation();
    const [isMobile, setIsMobile] = useState(false);
    const { isAuthenticated } = useSelector((state) => state.auth);

    const handleSignOut = () => {
        dispatch(signOutAction());
    };

    useEffect(() => {
        const handleResize = () => {
          setIsMobile(window.innerWidth <= 600); // Define the breakpoint for mobile
        };
    
        // Set initial value
        handleResize();
    
        // Add event listener
        window.addEventListener('resize', handleResize);
    
        // Cleanup the event listener
        return () => window.removeEventListener('resize', handleResize);
      }, []);

    return (
        <div>
            <RedirectToSignin />
            <RedirectToHome />
            {!isMobile ? (
                <SecondaryHeader />
            ) : (
                <PrimaryMobileHeader />
            )}
            <div className='container'>
                <div className='tendorsPage mt-4 pb-5'>
                    <div className='row justify-content-center'>
                        <div className='col-12 col-sm-10'>
                            <h2 className='mb-4  text-center' ref={(el) => (elementsRef.current[0] = el)} data-animation="animate__fadeInDown">Our Team</h2>
                            <div className='row g-3 g-sm-4 mb-5' ref={(el) => (elementsRef.current[1] = el)} data-animation="animate__fadeInUp">
                                <div className='col-6 col-sm-3'>
                                    <img src={placeHolder} alt='Team Member' className='img-fluid mb-3' />
                                    <h5 className='m-0'>Jerome Bell</h5>
                                    <p className='m-0 font-13'>Software Tester</p>
                                </div>
                                <div className='col-6 col-sm-3'>
                                    <img src={placeHolder} alt='Team Member' className='img-fluid mb-3' />
                                    <h5 className='m-0'>Jerome Bell</h5>
                                    <p className='m-0 font-13'>Software Tester</p>
                                </div>
                                <div className='col-6 col-sm-3'>
                                    <img src={placeHolder} alt='Team Member' className='img-fluid mb-3' />
                                    <h5 className='m-0'>Jerome Bell</h5>
                                    <p className='m-0 font-13'>Software Tester</p>
                                </div>
                                <div className='col-6 col-sm-3'>
                                    <img src={placeHolder} alt='Team Member' className='img-fluid mb-3' />
                                    <h5 className='m-0'>Jerome Bell</h5>
                                    <p className='m-0 font-13'>Software Tester</p>
                                </div>
                                <div className='col-6 col-sm-3'>
                                    <img src={placeHolder} alt='Team Member' className='img-fluid mb-3' />
                                    <h5 className='m-0'>Jerome Bell</h5>
                                    <p className='m-0 font-13'>Software Tester</p>
                                </div>
                                <div className='col-6 col-sm-3'>
                                    <img src={placeHolder} alt='Team Member' className='img-fluid mb-3' />
                                    <h5 className='m-0'>Jerome Bell</h5>
                                    <p className='m-0 font-13'>Software Tester</p>
                                </div>
                                <div className='col-6 col-sm-3'>
                                    <img src={placeHolder} alt='Team Member' className='img-fluid mb-3' />
                                    <h5 className='m-0'>Jerome Bell</h5>
                                    <p className='m-0 font-13'>Software Tester</p>
                                </div>
                                <div className='col-6 col-sm-3'>
                                    <img src={placeHolder} alt='Team Member' className='img-fluid mb-3' />
                                    <h5 className='m-0'>Jerome Bell</h5>
                                    <p className='m-0 font-13'>Software Tester</p>
                                </div>
                            </div>

                            {/* <h3 className='mb-3' ref={(el) => (elementsRef.current[2] = el)} data-animation="animate__fadeInDown">Need Some Inspiration?</h3> */}
                            {/* <div className='justify-content-start align-items-center' ref={(el) => (elementsRef.current[3] = el)} data-animation="animate__fadeInUp">
                                <Link to="/" className='bg-light rounded p-2 ps-3 pe-3 text-decoration-none text-dark me-3 font-13'>Automatics Cars</Link>
                                <Link to="/" className='bg-light rounded p-2 ps-3 pe-3 text-decoration-none text-dark me-3 font-13'>SUVs</Link>
                                <Link to="/" className='bg-light rounded p-2 ps-3 pe-3 text-decoration-none text-dark me-3 font-13'>Electrics Cars</Link>
                                <Link to="/" className='bg-light rounded p-2 ps-3 pe-3 text-decoration-none text-dark me-3 font-13'>New in Stock</Link>
                                <Link to="/" className='bg-light rounded p-2 ps-3 pe-3 text-decoration-none text-dark me-3 font-13'>Petrol</Link>
                                <Link to="/" className='bg-light rounded p-2 ps-3 pe-3 text-decoration-none text-dark me-3 font-13'>Diesel</Link>
                            </div> */}

                            <Brands />
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default OurTeam;
