import React from 'react';
import ImgsViewer from 'react-images-viewer';
import {Modal} from 'react-bootstrap';

const ImagePreviewModal = ({
                               isModalOpen,
                               setIsModalOpen,
                               setThumbsSwiper,
                               activeIndex,
                               setActiveIndex,
                               thumbsSwiper,
                               orderedImages,
                               api_url,
                           }) => {
    const gotoPrev = () => {
        setActiveIndex(activeIndex - 1);
    };

    const gotoNext = () => {
        setActiveIndex(activeIndex + 1);
    };

    const gotoImg = (index) => {
        setActiveIndex(index);
    };

    const handleClickImg = () => {
        if (activeIndex === orderedImages.length - 1) return;
        gotoNext();
    };

    return (
        <Modal
            show={isModalOpen}
            onHide={() => {
                setIsModalOpen(false);
                setThumbsSwiper(null);
            }}
            fullscreen={true}
            className="fullscreen-modal"
        >
            <Modal.Body className="p-0 m-0">
                <div className="d-flex justify-content-center align-items-center w-100" style={{ height: '100vh' }}>
                    <img
                        src={`${api_url}/${orderedImages[activeIndex]?.image?.path}`}
                        alt="Preview"
                        style={{
                            objectFit: 'contain',
                            width: '100%',
                            height: '100%',
                            maxHeight: '100vh',
                        }}
                    />
                </div>

                <i
                    className="bi bi-x text-white border rounded p-1 py-0 font-30 shadow bg-dark position-absolute"
                    style={{ cursor: 'pointer', right: '20px', top: '10px', zIndex: 1000 }}
                    onClick={() => {
                        setIsModalOpen(false);
                        setActiveIndex(0);
                    }}
                ></i>

                <div
                    className="d-flex justify-content-center overflow-x-auto position-absolute w-100"
                    style={{
                        bottom: '10px',
                        padding: '10px',
                        backgroundColor: 'rgba(0,0,0,0.5)',
                    }}
                >
                    <div className="d-flex gap-2">
                        {orderedImages.map((image, index) => (
                            <img
                                key={index}
                                src={`${api_url}/${image?.image?.path}`}
                                alt={image?.name}
                                onClick={() => gotoImg(index)}
                                className={`thumbnail-img ${index === activeIndex ? 'border border-3 border-primary' : ''}`}
                                style={{
                                    width: '150px',
                                    // height: '60px',
                                    objectFit: 'cover',
                                    cursor: 'pointer',
                                    borderRadius: '5px',
                                }}
                            />
                        ))}
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ImagePreviewModal;