// import './ServicePage.css';
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { signOutAction } from "../redux/auth/authActions";
import { Navigate, Link } from "react-router-dom";
import SecondaryHeader from "../components/SecondaryHeader";
import Footer from "../components/Footer";
import banner from "../assets/images/cryptocurrency.png";
import RedirectToHome from "../components/RedirectToHome";
import RedirectToSignin from "../components/RedirectToSignin";
import PrimaryMobileHeader from "../components/PrimaryMobileHeader";

const CryptoCurrency = () => {
  const dispatch = useDispatch();
  const [isMobile, setIsMobile] = useState(false);
  const { isAuthenticated } = useSelector((state) => state.auth);

  const handleSignOut = () => {
    dispatch(signOutAction());
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600); // Define the breakpoint for mobile
    };

    // Set initial value
    handleResize();

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="bg-white">
      <RedirectToSignin />
      <RedirectToHome />
      {!isMobile ? <SecondaryHeader /> : <PrimaryMobileHeader />}
      <div className="container">
        <div className="servicesPageList mt-4 pb-5">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-7">
              <img src={banner} alt="Banner" className="img-fluid mb-5" />
              <h2 className="mb-4 text-center">USDT & Cryptocurrency</h2>

              <div class="container-text">
                <div class="fade-container">
                  <div class="scrollable-section">
                    <div class="scrollable-content ms-2">
                      <div class="row">
                        {/* <!-- Pay with USDT and Cryptocurrency Section --> */}
                        <div class="col-12 pb-5 pt-3">
                          <h3 class="fw-bold">
                            Pay with USDT and Cryptocurrency
                          </h3>
                          <p class="fw-bold">
                            Fast. Secure. Global. Experience the Future of
                            Payments.
                          </p>

                          <h4 class="fw-bold">Why Cryptocurrency?</h4>
                          <p>
                            Paying with USDT and other cryptocurrencies isn’t
                            just a trend; it’s a game-changer in how we handle
                            transactions. Whether you’re tired of high fees,
                            slow transfers, or geographic limitations,
                            cryptocurrency payments offer a solution. Here’s why
                            paying with USDT could be your smartest move:
                          </p>
                          <ul>
                            <li>
                              <strong>Speed:</strong> Crypto payments are
                              processed almost instantly—no more waiting days
                              for bank transfers or international payments.
                            </li>
                            <li>
                              <strong>Lower Fees:</strong> Say goodbye to high
                              processing fees! With USDT, you’re only paying
                              minimal transaction costs.
                            </li>
                            <li>
                              <strong>Global Accessibility:</strong> No matter
                              where you are in the world, crypto payments break
                              down borders, allowing you to pay from any
                              country, at any time.
                            </li>
                          </ul>

                          <h4 class="fw-bold">How to Pay with USDT</h4>
                          <p>
                            We’ve made it super simple to pay with USDT or other
                            cryptocurrencies. Just follow these steps:
                          </p>
                          <ol>
                            <li>
                              <strong>Step 1:</strong> Select "Pay with Crypto"
                              at checkout.
                            </li>
                            <li>
                              <strong>Step 2:</strong> Choose USDT or another
                              available cryptocurrency.
                            </li>
                            <li>
                              <strong>Step 3:</strong> Copy the provided wallet
                              address or scan the QR code to initiate payment.
                            </li>
                            <li>
                              <strong>Step 4:</strong> Complete the transaction
                              in your crypto wallet. That’s it!
                            </li>
                          </ol>
                          <p>
                            Once the payment is confirmed on the blockchain,
                            you’ll receive a notification, and your order will
                            be processed immediately. No middlemen, no delays!
                          </p>

                          <h4 class="fw-bold">Is It Safe?</h4>
                          <p>
                            Absolutely. Using USDT and other cryptocurrencies is
                            one of the safest ways to pay online today. With
                            blockchain technology, your payments are encrypted,
                            transparent, and fully traceable. You control your
                            funds until the moment you decide to make the
                            payment—keeping everything under your control, every
                            step of the way.
                          </p>

                          <h4 class="fw-bold">Frequently Asked Questions</h4>
                          <ul>
                            <li>
                              <strong>Q:</strong> Can I pay with other
                              cryptocurrencies besides USDT?
                            </li>
                            <li>
                              <strong>A:</strong> Yes! We accept Bitcoin (BTC),
                              Ethereum (ETH), and several other popular
                              cryptocurrencies.
                            </li>

                            <li>
                              <strong>Q:</strong> How do I get USDT?
                            </li>
                            <li>
                              <strong>A:</strong> You can purchase USDT on most
                              major cryptocurrency exchanges, including Binance,
                              Coinbase, and Kraken. Once you have USDT in your
                              wallet, you’re ready to go!
                            </li>

                            <li>
                              <strong>Q:</strong> What happens if I make a
                              mistake during payment?
                            </li>
                            <li>
                              <strong>A:</strong> Double-check your wallet
                              address before sending any payment, as crypto
                              transactions are irreversible. If you encounter an
                              issue, reach out to our support team for
                              assistance.
                            </li>
                          </ul>

                          <h4 class="fw-bold">Ready to Pay with Crypto?</h4>
                          <p>
                            Embrace the future of payments today. Select USDT or
                            your favorite cryptocurrency at checkout and enjoy
                            the benefits of fast, secure, and global
                            transactions.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default CryptoCurrency;
