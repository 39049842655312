import { API_URL } from "../api/authApi";
import imgPlaceholder from "../assets/images/img-placeholder.png";
import {
  EditOutlined,
  EllipsisOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { Badge, Avatar, Card, Tag } from "antd";
import { useNavigate, Link } from "react-router-dom";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { bookCar } from "../redux/auth/authActions";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
import React, { useEffect, useState } from "react";
const { Meta } = Card;

const ProductCard = ({
  productData,
  btnText,
  fromPage,
  onSelectProduct,
  selectedProduct,
}) => {
  const dispatch = useDispatch();
  const api_url = API_URL.replace("/api", "");
  const { t } = useTranslation();
  const navigate = useNavigate();
  // const [isSelected, setIsSelected] = useState(false);
  const isSelected = selectedProduct?.id === productData?.id;
  const thumbnailImage = productData?.images?.find(
    (img) => img?.is_thumbnail === 1
  );
  const { isAuthenticated, user } = useSelector((state) => state.auth);
  const userData = user?.data?.user || {};
  const thumbUrl = thumbnailImage
    ? `${api_url}/${thumbnailImage?.image?.path}` // Use the path of the thumbnail image
    : imgPlaceholder;
  const setNameCar = () => {
    const {
      steering_type,
      name,
      model,
      engine_size,
      fuel_type,
      transmission,
      ext_color,
      production_year,
    } = productData || {};
    const transmissionLabel =
      productData?.transmission === "Automatic" ? "AT" : "ML";
    const carName = `${steering_type ? `(${steering_type})` : ""} ${
      name ? name : ""
    } ${model ? model : ""} ${engine_size ? engine_size : ""} ${
      fuel_type ? fuel_type : ""
    } ${transmissionLabel ? transmissionLabel : ""} ${
      ext_color ? ext_color : ""
    } - ${production_year ? `MY${production_year}` : ""}`;
    return carName.trim();
  };

  const handleCheckboxChange = (e) => {
    const checked = e.target.checked;
    onSelectProduct(productData, checked); // Notify parent about the selection
  };

  useEffect(() => {
    const detectedLanguage =
      localStorage.getItem("selectedLanguage") ||
      navigator.language.split("-")[0] ||
      "en";
    i18n.changeLanguage(detectedLanguage);
    document.documentElement.lang = detectedLanguage;
  }, []);
  const handleBookCar = (e, id) => {
    e.stopPropagation();
    Swal.fire({
      title: "Ready to Book Your Car?",
      text: "We will reserve the car for you once you confirm.",
      icon: "info", // More friendly icon
      showCancelButton: true,
      confirmButtonColor: "#4e3DBD", // Custom primary color
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Book it!",
      cancelButtonText: "No, Cancel",
      background: "#f7f9fc", // Light background
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await dispatch(bookCar(id));
        if (response?.code === 200) {
          const refId = response?.data?.code;
          const email = userData?.email;

          Swal.fire({
            title: `${t("booked")}`,
            html: `<p>${t("booking_pop_1")}<strong>${email}</strong>.</p>
                   <p>${t("booking_pop_2")}<strong>${refId}</strong> ${t(
              "booking_pop_3"
            )}</p>
                   <p>${t("booking_pop_4")}</p>
                   <p><strong>${t("booking_pop_5")}</strong></p>`,
            icon: "success",
            confirmButtonText: `${t("Go To Modification")}`, // Custom button text
            confirmButtonColor: "#000740", // Custom primary color
            timer: 5000, // 5 seconds auto-dismiss
            timerProgressBar: true, // Show timer progress bar
            willClose: () => {
              navigate(`/modifications?car=${productData?.slug}`, {
                state: { slug: productData?.slug },
              }); // Auto navigate when Swal closes
            },
          }).then((result) => {
            if (result.isConfirmed) {
              navigate(`/modifications?car=${productData?.slug}`, {
                state: { slug: productData?.slug },
              });
            }
          });
        }
      }
    });
  };
  const handleCardClick = () => {
    const queryParams = new URLSearchParams();
    queryParams.append("car", productData?.slug);
    const queryString = queryParams.toString();
    if (queryString) {
      navigate(`/car-specification?${queryString}`);
    }
  };
  const downloadPdf = (e, data) => {
    e.stopPropagation(); // Prevent other events from being triggered

    const fileUrl = `${api_url}/${data?.[0]?.file?.path}`; // Construct the full file URL
    const fileName = data?.file?.original_name || "file.pdf"; // Use the original name or a fallback name
    window.open(fileUrl, "_blank");
  };

  return (
    <>
      <Badge.Ribbon text="New" placement="start" color="#1677ff">
        <Link
          to={{
            pathname: "/car-specification",
            search: `?car=${productData?.slug}`,
          }}
          style={{ textDecoration: "none" }}
        >
          <Card
            className="w-100 h-100 text-center rounded-3 border-0 shadow mb-2 highlightHover"
            hoverable
            onClick={handleCardClick}
            cover={
              <img
                alt={productData?.name || "Product Image"}
                src={thumbUrl}
                className="rounded-top"
                style={{
                  height: "250px", // Responsive height
                  objectFit: "cover", // Maintain image ratio without stretching
                }}
              />
            }
          >
            <div className="product-card-body">
              <Meta
                //   avatar={<Avatar src="https://api.dicebear.com/7.x/miniavs/svg?seed=8" />}
                className="mb-2 two-line-clamp text-align-start"
                title={productData?.name}
                // description="This is the description"
              />
              <div className="d-flex justify-content-between align-items-center mb-2 custom-border">
                <div className="text-muted font-13 fw-bold custom-border-item">
                  {productData?.steering_type}
                </div>
                <div className="text-muted font-13 fw-bold custom-border-item">
                  {productData?.production_year}
                </div>
                <div className="text-muted font-13 fw-bold custom-border-item">
                  {productData?.ext_color}
                </div>
              </div>
              {btnText}

              <div>
                {btnText ? (
                  <button
                    type="button"
                    className="btn btn-primary w-100"
                    onClick={(e) => downloadPdf(e, productData?.attachments)}
                  >
                    {btnText}
                  </button>
                ) : !productData?.is_booked ? (
                  <button
                    type="button"
                    className="btn btn-primary w-100"
                    onClick={(e) => handleBookCar(e, productData?.id)}
                  >
                    {t("book")}
                  </button>
                ) : (
                  <button type="button" className="btn btn-primary w-100">
                    {t("booked")}: {productData?.booked_code}
                  </button>
                )}
              </div>
            </div>
            {fromPage === "modifications" && (
              <div
                className="d-flex justify-content-center align-items-center mt-2"
                onClick={(e) => e.stopPropagation()}
              >
                <label className="form-check-label me-2">Select</label>
                <input
                  type="checkbox"
                  className="form-check-input"
                  checked={isSelected}
                  onChange={(e) => {
                    handleCheckboxChange(e); // Handle the checkbox change
                  }}
                />
              </div>
            )}
          </Card>
        </Link>
      </Badge.Ribbon>
      {/* <div className='card rounded-10'>
            <div className='card-body'>
                <div className='inm-col mb-3'>
                    <img src={image} alt={title} title={title} className='img-fluid rounded-10' />
                </div>
                <button type='button' className='btn btn-primary w-100'><i class="bi bi-cloud-download"></i> Download</button>
            </div>
        </div> */}
    </>
  );
};

export default ProductCard;
