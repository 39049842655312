import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { signOutAction } from "../redux/auth/authActions";
import { Navigate, Link } from "react-router-dom";
import SecondaryHeader from "../components/SecondaryHeader";
import Footer from "../components/Footer";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import banner from "../assets/images/warranty-information.png";
import RedirectToSignin from "../components/RedirectToSignin";
import RedirectToHome from "../components/RedirectToHome";
import PrimaryMobileHeader from "../components/PrimaryMobileHeader";

import img1 from "../assets/images/about-us/g1.jpg.png";
import img2 from "../assets/images/about-us/g2.jpg.png";
import img3 from "../assets/images/about-us/g3.jpg.png";
import img4 from "../assets/images/about-us/g4.jpg.png";
import img5 from "../assets/images/about-us/g5.jpg.png";
import frame from "../assets/images/about-us/Frame 1984077861.png";

import { ReactComponent as FinanceIcon } from "../assets/images/about-us/f1.svg.svg"; // Path to your SVG icon
import { ReactComponent as DealershipIcon } from "../assets/images/about-us/f2.svg.svg"; // Path to your SVG icon
import { ReactComponent as PricingIcon } from "../assets/images/about-us/f3.svg.svg"; // Path to your SVG icon
import { ReactComponent as ServiceIcon } from "../assets/images/about-us/f4.svg.svg";
import "../styles/AboutUsNew.css";

const AboutUsNew = () => {
  const dispatch = useDispatch();
  const [isMobile, setIsMobile] = useState(false);
  const { isAuthenticated } = useSelector((state) => state.auth);

  const handleSignOut = () => {
    dispatch(signOutAction());
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600); // Define the breakpoint for mobile
    };

    // Set initial value
    handleResize();

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const stats = [
    { number: "836M", label: "CARS FOR SALE" },
    { number: "738M", label: "DEALER REVIEWS" },
    { number: "95M", label: "VISITORS PER DAY" },
    { number: "238M", label: "VERIFIED DEALERS" },
  ];

  return (
    <div className="bg-white">
      <RedirectToSignin />
      <RedirectToHome />
      {!isMobile ? <SecondaryHeader /> : <PrimaryMobileHeader />}
      <div className="container">
        <div className="tendorsPage mt-4 pb-5">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-7">
              <div className="row g-3 mb-4 ">
                <div className="col-12 col-sm-12">
                  <section class="about-us">
                    <h1 className="mb-3">About Us</h1>
                    <p>
                      Welcome to Cruise Motors, your trusted destination for
                      high-quality vehicles and an unmatched car-buying
                      experience. At Cruise Motors, we believe that finding your
                      dream car should be exciting, enjoyable, and effortless.
                      Our mission is to bring you a handpicked selection of
                      vehicles that meet the highest standards of performance,
                      safety, and style.
                    </p>
                    <p>
                      With years of industry expertise, our team is committed to
                      understanding your needs and helping you make an informed
                      choice. We are passionate about cars and dedicated to
                      providing transparent and personalized service, guiding
                      you every step of the way to ensure you drive away with
                      confidence and satisfaction.
                    </p>
                    <p>
                      Our inventory spans a wide range of vehicles to suit every
                      lifestyle and budget, from luxury sedans and SUVs to
                      reliable family cars and fuel-efficient options. Whether
                      you're purchasing your first car or upgrading to your
                      dream model, we're here to make the journey seamless.
                    </p>
                    <p>
                      At Cruise Motors, we don't just sell cars; we build
                      lasting relationships. We look forward to welcoming you to
                      our growing family of happy customers who have discovered
                      the Cruise Motors difference.
                    </p>
                  </section>
                  <div className="my-5">
                    <div className="row mb-3">
                      <img src={frame} />
                    </div>
                  </div>
                  <div className="my-5">
                    <h2 className=" mb-4">Why Choose Us?</h2>
                    <div className="row ">
                      <div className="col-3">
                        <FinanceIcon
                          className="mb-3"
                          style={{ width: "50px", height: "50px" }}
                        />
                        <h5>Special Financing Offers</h5>
                        <p>
                          Our stress-free finance department that can find
                          financial solutions to save you money.
                        </p>
                      </div>
                      <div className="col-3">
                        <DealershipIcon
                          className="mb-3"
                          style={{ width: "50px", height: "50px" }}
                        />
                        <h5>Trusted Car Dealership</h5>
                        <p>
                          Partner with a dealership known for trust,
                          transparency, and exceptional service.
                        </p>
                      </div>
                      <div className="col-3">
                        <PricingIcon
                          className="mb-3"
                          style={{ width: "50px", height: "50px" }}
                        />
                        <h5>Transparent Pricing</h5>
                        <p>
                          Experience upfront, competitive pricing with no hidden
                          fees or surprises.
                        </p>
                      </div>
                      <div className="col-3">
                        <ServiceIcon
                          className="mb-3"
                          style={{ width: "50px", height: "50px" }}
                        />
                        <h5>Expert Car Service</h5>
                        <p>
                          Our certified technicians provide top-notch
                          maintenance to keep your vehicle running smoothly.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className=" my-2">
                    <div className="row text-center d-flex justify-content-between">
                      {stats.map((stat, index) => (
                        <div key={index} className="col">
                          <div style={{ fontSize: "24px", fontWeight: "bold" }}>
                            {stat.number}
                          </div>
                          <div style={{ fontSize: "16px", color: "black" }}>
                            {stat.label}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  {/* <Link
                    to="/contact-us"
                    className="btn btn-primary btn-lg ps-5 pe-5 font-15 mb-3"
                  >
                    Contact Us for more information
                  </Link> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AboutUsNew;
