import {
  signIn,
  signUp,
  signOut,
  tendorSubmit,
  supplyContractSubmit,
  requestQuotationSubmit,
  makeaDealSubmit,
  requestOtp,
  verifyOtp,
  resetPassword,
  companySignUp,
  offerVehicle,
  orderVehicle,
  getAccessoriesApi,
  getServiceKitsApi,
  getSparePartsApi,
  getCategoriesApi,
  getBrandListAPi,
  getWishListAPi,
  removeWishListApi,
  getProductDetailsApi,
  getCarMakeApi,
  getCarModelApi,
  getCountryApi,
  getStateApi,
  getCityApi,
  getPortApi,
  getCartApi,
  cartUpdateApi,
  contactUsApi,
  getPaymentMethodApi,
  getNewArrivalsApi,
  getCarsByBrandApi,
  getCarBySlugApi,
  getFeatureBrandListAPi,
  getCertificatesApi,
  documentationFormApi,
  bookCarApi,
  getInquiriesApi,
  getDealFormApi,
  getOrderVehicleFormApi,
  getSupplyContractFormApi,
  getTenderFormApi,
  getQuotationFormApi,
  getDocumentationFormApi,
  getOfferVehicleFormApi,
  updateProfileApi,
  updatePasswordApi,
  getCarBookingsApi,
  priceEnquiryApi,
  generalSearchApi,
  getInstagramPostsApi,
  getAllCarsApi,
  getModifiedCarsApi,
  getDepartmentsApi,
  startNewChatApi,
  getPreviousChatApi,
  getMessagesByChatroomApi,
  sendMessagesApi,
} from "../../api/authApi";
import {
  SIGN_IN_SUCCESS,
  SIGN_IN_FAILURE,
  SIGN_OUT,
  SIGN_UP_SUCCESS,
  ADVANCE_SIGN_UP_SUCCESS,
  COMPANY_SIGN_UP_SUCCESS,
  COMPANY_ADVANCE_SIGN_UP_SUCCESS,
  SIGN_UP_FAILURE,
  SET_LANGUAGE,
  TENDOR_SUBMIT,
  SUPPLY_CONTRACT_SUBMIT,
  REQUEST_QUOTATION_SUBMIT,
  MAKE_A_DEAL_SUBMIT,
  FORGOT_PASSWORD,
  VERIFY_OTP,
  RESET_PASSWORD,
  OFFER_VEHICLE,
  ORDER_VEHICLE,
  GET_ACCESSORIES,
  GET_SPARE_PARTS,
  GET_SERVICE_KITS,
  GET_CATEGORIES_API,
  GET_ALL_BRAND_LIST,
  GET_CAR_MAKE,
  GET_CAR_MODEL,
  GET_FEATURED_BRAND_LIST,
  GET_ALL_COUNTRY,
  GET_COLORS,
  GET_FEATURED_BRAND_LIST_WITH_CARS,
  SET_USERDATA,
  SET_CART_ITEMS,
  SET_WISH_LIST,
  SET_INSTAGRAM_POSTS,
} from "./authTypes";

const TOKEN_EXPIRATION_DAYS = 3; // Set expiration duration

export const setCurrentLanguage = (lang) => async (dispatch) => {
  dispatch({ type: SET_LANGUAGE, payload: lang });
};

export const signInAction = (credentials) => async (dispatch) => {
  // Dummy Signin
  const { email, password } = credentials;
  credentials.device_name = "web_app";

  const dummyUser = { email: "test@gmail.com", password: "123456" };

  // if (email === dummyUser.email && password === dummyUser.password) {

  //     // Set expiration time
  //     const expirationDate = new Date();
  //     expirationDate.setDate(expirationDate.getDate() + TOKEN_EXPIRATION_DAYS);

  //     const userWithExpiry = {
  //         ...dummyUser,
  //         expiration: expirationDate.toISOString() // Store expiration timestamp
  //     };

  //     // localStorage.setItem('user', JSON.stringify(userWithExpiry)); // Save to local storage
  //     dispatch({ type: SIGN_IN_SUCCESS, payload: userWithExpiry });
  // } else {
  //     dispatch({ type: SIGN_IN_FAILURE, payload: 'Invalid email or password' });
  // }
  // Dummy Signin End

  // Use After you get API

  try {
    const data = await signIn(credentials);

    localStorage.setItem("authToken", data?.data?.token?.access_token);
    if (data?.data) {
      dispatch({ type: SIGN_IN_SUCCESS, payload: data });
    }

    return data;
  } catch (error) {
    const errorMessage = error?.message || "Wrong password or username";
    dispatch({ type: SIGN_IN_FAILURE, payload: errorMessage });
  }
};

export const signUpAction = (credentials) => async (dispatch) => {
  // dispatch({ type: SIGN_UP_SUCCESS, payload: credentials });

  // Use After you get API

  try {
    const data = await signUp(credentials);
    dispatch({ type: SIGN_UP_SUCCESS, payload: data });
    return data;
  } catch (error) {
    const errorMessage = error?.message || "Sign Up Failed Somthing Wrong!";
    dispatch({ type: SIGN_UP_FAILURE, payload: errorMessage });
  }
};

export const companySignUpAction = (credentials) => async (dispatch) => {
  // dispatch({ type: COMPANY_SIGN_UP_SUCCESS, payload: credentials });

  // Use After you get API

  try {
    const data = await companySignUp(credentials);
    dispatch({ type: COMPANY_SIGN_UP_SUCCESS, payload: data });
    return data;
  } catch (error) {
    const errorMessage = error?.message || "Sign Up Failed Somthing Wrong!";
    dispatch({ type: SIGN_UP_FAILURE, payload: errorMessage });
  }
};

export const signOutAction = () => async (dispatch) => {
  localStorage.removeItem("user");
  localStorage.removeItem("authToken"); // Remove from local storage
  dispatch({ type: SIGN_OUT });

  // try {
  //     await signOut();
  //     dispatch({ type: SIGN_OUT });
  // } catch (error) {
  //     // Handle sign out error if needed
  // }
};

export const submitTendorForm = (credentials) => async (dispatch) => {
  // dispatch({ type: TENDOR_SUBMIT, payload: credentials });

  // Use After you get API

  try {
    const data = await tendorSubmit(credentials);
    dispatch({ type: TENDOR_SUBMIT, payload: credentials });
    return data;
  } catch (error) {
    const errorMessage = error?.message || "Submission failed";
    dispatch({ type: SIGN_UP_FAILURE, payload: errorMessage });
  }
};

export const submitSupplyContractForm = (credentials) => async (dispatch) => {
  // dispatch({ type: SUPPLY_CONTRACT_SUBMIT, payload: credentials });

  // Use After you get API

  try {
    const data = await supplyContractSubmit(credentials);
    dispatch({ type: SUPPLY_CONTRACT_SUBMIT, payload: credentials });
    return data;
  } catch (error) {
    const errorMessage = error?.message || "Submission failed";
    dispatch({ type: SIGN_UP_FAILURE, payload: errorMessage });
  }
};

export const submitRequestQuotationForm = (credentials) => async (dispatch) => {
  // dispatch({ type: REQUEST_QUOTATION_SUBMIT, payload: credentials });
  // Use After you get API

  try {
    const data = await requestQuotationSubmit(credentials);
    dispatch({ type: REQUEST_QUOTATION_SUBMIT, payload: credentials });
    return data;
  } catch (error) {
    const errorMessage = error?.message || "Submission failed";
    dispatch({ type: SIGN_UP_FAILURE, payload: errorMessage });
  }
};

export const submitMakeADealForm = (credentials) => async (dispatch) => {
  // dispatch({ type: MAKE_A_DEAL_SUBMIT, payload: credentials });

  // Use After you get API

  try {
    const data = await makeaDealSubmit(credentials);
    dispatch({ type: MAKE_A_DEAL_SUBMIT, payload: credentials });
    return data;
  } catch (error) {
    const errorMessage = error.response?.data || "Submission failed";
    dispatch({ type: SIGN_UP_FAILURE, payload: errorMessage });
  }
};

export const submitRequestOtp = (credentials) => async (dispatch) => {
  // dispatch({ type: FORGOT_PASSWORD, payload: credentials });
  // Use After you get API

  try {
    const data = await requestOtp(credentials);
    dispatch({ type: FORGOT_PASSWORD, payload: credentials });
    return data;
  } catch (error) {
    console.log("error", error);  
    return error;
    const errorMessage = error?.message || "Failed to send OTP!";
    dispatch({ type: SIGN_UP_FAILURE, payload: errorMessage });
  }
};

export const submitVerifyOtp = (credentials) => async (dispatch) => {
  // dispatch({ type: VERIFY_OTP, payload: credentials });
  // Use After you get API

  try {
    const data = await verifyOtp(credentials);
    dispatch({ type: VERIFY_OTP, payload: credentials });
    return data;
  } catch (error) {
    const errorMessage = error?.message || "OTP verification failed!";
    dispatch({ type: SIGN_UP_FAILURE, payload: errorMessage });
  }
};

export const submitResetPassword = (credentials) => async (dispatch) => {
  // dispatch({ type: VERIFY_OTP, payload: credentials });
  // Use After you get API

  try {
    const data = await resetPassword(credentials);
    dispatch({ type: RESET_PASSWORD, payload: credentials });
    return data;
  } catch (error) {
    const errorMessage = error?.message || "Failed to reset password!";
    dispatch({ type: SIGN_UP_FAILURE, payload: errorMessage });
  }
};

export const offerVehicleForm = (credentials) => async (dispatch) => {
  // dispatch({ type: OFFER_VEHICLE, payload: credentials });
  // Use After you get API

  try {
    const data = await offerVehicle(credentials);
    dispatch({ type: OFFER_VEHICLE, payload: credentials });
    return data;
  } catch (error) {
    const errorMessage = error?.message || "Submission failed";
    dispatch({ type: SIGN_UP_FAILURE, payload: errorMessage });
  }
};

export const orderVehicleForm = (credentials) => async (dispatch) => {
  // dispatch({ type: ORDER_VEHICLE, payload: credentials });
  // Use After you get API

  try {
    const data = await orderVehicle(credentials);
    dispatch({ type: ORDER_VEHICLE, payload: credentials });
    return data;
  } catch (error) {
    const errorMessage = error?.message || "Submission failed!";
    dispatch({ type: SIGN_UP_FAILURE, payload: errorMessage });
  }
};

export const getCategories = (credentials) => async (dispatch) => {
  // dispatch({ type: ORDER_VEHICLE, payload: credentials });
  // Use After you get API

  try {
    const data = await getCategoriesApi(credentials);
    dispatch({ type: GET_CATEGORIES_API, payload: credentials });
    return data;
  } catch (error) {
    const errorMessage = error?.message || "Request Failed";
    dispatch({ type: SIGN_UP_FAILURE, payload: errorMessage });
  }
};

export const getAccessories = (credentials) => async (dispatch) => {
  // dispatch({ type: ORDER_VEHICLE, payload: credentials });
  // Use After you get API

  try {
    const data = await getAccessoriesApi(credentials);
    dispatch({ type: GET_ACCESSORIES, payload: credentials });
    return data;
  } catch (error) {
    const errorMessage = error?.message || "Request Failed";
    dispatch({ type: SIGN_UP_FAILURE, payload: errorMessage });
  }
};

export const getSpareParts = (credentials) => async (dispatch) => {
  // dispatch({ type: ORDER_VEHICLE, payload: credentials });
  // Use After you get API

  try {
    const data = await getSparePartsApi(credentials);
    dispatch({ type: GET_SPARE_PARTS, payload: credentials });
    return data;
  } catch (error) {
    const errorMessage = error?.message || "Request Failed";
    dispatch({ type: SIGN_UP_FAILURE, payload: errorMessage });
  }
};

export const getServiceKits = (credentials) => async (dispatch) => {
  // dispatch({ type: ORDER_VEHICLE, payload: credentials });
  // Use After you get API

  try {
    const data = await getServiceKitsApi(credentials);
    dispatch({ type: GET_SERVICE_KITS, payload: credentials });
    return data;
  } catch (error) {
    const errorMessage = error?.message || "Request Failed";
    dispatch({ type: SIGN_UP_FAILURE, payload: errorMessage });
  }
};

export const getBrandList = (credentials) => async (dispatch) => {
  // dispatch({ type: ORDER_VEHICLE, payload: credentials });
  // Use After you get API

  try {
    const data = await getBrandListAPi(credentials);
    dispatch({ type: GET_ALL_BRAND_LIST, payload: data?.data });
    return data;
  } catch (error) {
    const errorMessage = error?.message || "Request Failed";
    dispatch({ type: SIGN_UP_FAILURE, payload: errorMessage });
  }
};
export const getFeatureBrandList = (credentials) => async (dispatch) => {
  // dispatch({ type: ORDER_VEHICLE, payload: credentials });
  // Use After you get API

  try {
    const data = await getFeatureBrandListAPi(credentials);
    if (credentials === 1) {
      dispatch({
        type: GET_FEATURED_BRAND_LIST_WITH_CARS,
        payload: data?.data,
      });
    } else {
      dispatch({ type: GET_FEATURED_BRAND_LIST, payload: data?.data });
    }
    return data;
  } catch (error) {
    const errorMessage = error?.message || "Request Failed";
    dispatch({ type: SIGN_UP_FAILURE, payload: errorMessage });
  }
};
export const getWishList = (credentials) => async (dispatch) => {
  // dispatch({ type: ORDER_VEHICLE, payload: credentials });
  // Use After you get API

  try {
    const data = await getWishListAPi(credentials);
    dispatch({ type: SET_WISH_LIST, payload: data?.data });
    return data;
  } catch (error) {
    const errorMessage = error?.message || "Request Failed";
    dispatch({ type: SIGN_UP_FAILURE, payload: errorMessage });
  }
};

export const removeWishList = (credentials) => async (dispatch) => {
  // dispatch({ type: ORDER_VEHICLE, payload: credentials });
  // Use After you get API

  try {
    const data = await removeWishListApi(credentials);
    dispatch({ type: SET_WISH_LIST, payload: data?.data });
    return data;
  } catch (error) {
    const errorMessage = error?.message || "Request Failed";
    dispatch({ type: SIGN_UP_FAILURE, payload: errorMessage });
  }
};

export const getProductDetails = (credentials) => async (dispatch) => {
  // dispatch({ type: ORDER_VEHICLE, payload: credentials });
  // Use After you get API

  try {
    const data = await getProductDetailsApi(credentials);
    //   dispatch({ type: GET_WISH_LIST, payload: credentials });
    return data;
  } catch (error) {
    const errorMessage = error?.message || "Request Failed";
    dispatch({ type: SIGN_UP_FAILURE, payload: errorMessage });
  }
};

export const getCarMake = (credentials) => async (dispatch) => {
  // Use After you get API

  try {
    const data = await getCarMakeApi(credentials);
    //   dispatch({ type: GET_WISH_LIST, payload: credentials });
    dispatch({ type: GET_CAR_MAKE, payload: data?.data });
    return data;
  } catch (error) {
    const errorMessage = error?.message || "Request Failed";
  }
};
export const getCarModel = (credentials) => async (dispatch) => {
  try {
    const data = await getCarModelApi(credentials);
    // dispatch({ type: GET_CAR_MODEL, payload: data?.data });
    //   dispatch({ type: GET_WISH_LIST, payload: credentials });
    return data;
  } catch (error) {
    const errorMessage = error?.message || "Request Failed";
  }
};
export const getCountry = (credentials) => async (dispatch) => {
  try {
    const data = await getCountryApi(credentials);
    dispatch({ type: GET_ALL_COUNTRY, payload: data?.data });
    return data;
  } catch (error) {
    const errorMessage = error?.message || "Request Failed";
  }
};

export const getState = (credentials) => async (dispatch) => {
  try {
    const data = await getStateApi(credentials);
    //   dispatch({ type: GET_WISH_LIST, payload: credentials });
    return data;
  } catch (error) {
    const errorMessage = error?.message || "Request Failed";
  }
};
export const getCity = (credentials) => async (dispatch) => {
  try {
    const data = await getCityApi(credentials);
    //   dispatch({ type: GET_WISH_LIST, payload: credentials });
    return data;
  } catch (error) {
    const errorMessage = error?.message || "Request Failed";
  }
};
export const getPort = (credentials) => async (dispatch) => {
  try {
    const data = await getPortApi(credentials);
    //   dispatch({ type: GET_WISH_LIST, payload: credentials });
    return data;
  } catch (error) {
    const errorMessage = error?.message || "Request Failed";
  }
};

export const getCart = (credentials) => async (dispatch) => {
  try {
    const data = await getCartApi(credentials);
    dispatch({ type: SET_CART_ITEMS, payload: data?.data });
    return data;
  } catch (error) {
    const errorMessage = error?.message || "Request Failed";
  }
};

export const cartUpdate = (credentials) => async (dispatch) => {
  try {
    const data = await cartUpdateApi(credentials);

    dispatch({ type: SET_CART_ITEMS, payload: data?.data });
    return data;
  } catch (error) {
    const errorMessage = error?.message || "Request Failed";
  }
};

export const contactUs = (credentials) => async (dispatch) => {
  try {
    const data = await contactUsApi(credentials);
    //   dispatch({ type: GET_WISH_LIST, payload: credentials });
    return data;
  } catch (error) {
    const errorMessage = error?.message || "Request Failed";
  }
};
export const getPaymentMethod = (credentials) => async (dispatch) => {
  try {
    const data = await getPaymentMethodApi(credentials);
    //   dispatch({ type: GET_WISH_LIST, payload: credentials });
    return data;
  } catch (error) {
    const errorMessage = error?.message || "Request Failed";
  }
};

export const getNewArrivals = (credentials) => async (dispatch) => {
  try {
    const data = await getNewArrivalsApi(credentials);
    //   dispatch({ type: GET_WISH_LIST, payload: credentials });
    return data;
  } catch (error) {
    const errorMessage = error?.message || "Request Failed";
  }
};

export const getCarsByBrand = (credentials) => async (dispatch) => {
  try {
    const data = await getCarsByBrandApi(credentials);
    //   dispatch({ type: GET_WISH_LIST, payload: credentials });
    return data;
  } catch (error) {
    const errorMessage = error?.message || "Request Failed";
  }
};



export const getCarBySlug = (credentials) => async (dispatch) => {
  try {
    const data = await getCarBySlugApi(credentials);
    //   dispatch({ type: GET_WISH_LIST, payload: credentials });
    return data;
  } catch (error) {
    const errorMessage = error?.message || "Request Failed";
  }
};

export const getCertificates = (credentials) => async (dispatch) => {
  try {
    const data = await getCertificatesApi(credentials);
    //   dispatch({ type: GET_WISH_LIST, payload: credentials });
    return data;
  } catch (error) {
    const errorMessage = error?.message || "Request Failed";
  }
};

export const documentationForm = (credentials) => async (dispatch) => {
  // dispatch({ type: OFFER_VEHICLE, payload: credentials });
  // Use After you get API

  try {
    const data = await documentationFormApi(credentials);
    dispatch({ type: OFFER_VEHICLE, payload: credentials });
    return data;
  } catch (error) {
    const errorMessage = error?.message || "Submission failed";
    dispatch({ type: SIGN_UP_FAILURE, payload: errorMessage });
  }
};

export const bookCar = (credentials, selectedFeatures) => async (dispatch) => {
  try {
    console.log("credentials", credentials, selectedFeatures);

    const data = await bookCarApi(credentials, selectedFeatures);
    //   dispatch({ type: GET_WISH_LIST, payload: credentials });
    return data;
  } catch (error) {
    const errorMessage = error?.message || "Request Failed";
  }
};

export const getInquiries = (credentials) => async (dispatch) => {
  try {
    const data = await getInquiriesApi(credentials);
    //   dispatch({ type: GET_WISH_LIST, payload: credentials });
    return data;
  } catch (error) {
    const errorMessage = error?.message || "Request Failed";
  }
};
export const getDealForm = (credentials) => async (dispatch) => {
  try {
    const data = await getDealFormApi(credentials);
    //   dispatch({ type: GET_WISH_LIST, payload: credentials });
    return data;
  } catch (error) {
    const errorMessage = error?.message || "Request Failed";
  }
};
export const getOrderVehicleForm = (credentials) => async (dispatch) => {
  try {
    const data = await getOrderVehicleFormApi(credentials);
    //   dispatch({ type: GET_WISH_LIST, payload: credentials });
    return data;
  } catch (error) {
    const errorMessage = error?.message || "Request Failed";
  }
};
export const getSupplyContractForm = (credentials) => async (dispatch) => {
  try {
    const data = await getSupplyContractFormApi(credentials);
    //   dispatch({ type: GET_WISH_LIST, payload: credentials });
    return data;
  } catch (error) {
    const errorMessage = error?.message || "Request Failed";
  }
};
export const getTenderForm = (credentials) => async (dispatch) => {
  try {
    const data = await getTenderFormApi(credentials);
    //   dispatch({ type: GET_WISH_LIST, payload: credentials });
    return data;
  } catch (error) {
    const errorMessage = error?.message || "Request Failed";
  }
};
export const getQuotationForm = (credentials) => async (dispatch) => {
  try {
    const data = await getQuotationFormApi(credentials);
    //   dispatch({ type: GET_WISH_LIST, payload: credentials });
    return data;
  } catch (error) {
    const errorMessage = error?.message || "Request Failed";
  }
};
export const getDocumentationForm = (credentials) => async (dispatch) => {
  try {
    const data = await getDocumentationFormApi(credentials);
    //   dispatch({ type: GET_WISH_LIST, payload: credentials });
    return data;
  } catch (error) {
    const errorMessage = error?.message || "Request Failed";
  }
};
export const getOfferVehicleForm = (credentials) => async (dispatch) => {
  try {
    const data = await getOfferVehicleFormApi(credentials);
    //   dispatch({ type: GET_WISH_LIST, payload: credentials });
    return data;
  } catch (error) {
    const errorMessage = error?.message || "Request Failed";
  }
};
export const getCarBookings = (credentials) => async (dispatch) => {
  try {
    const data = await getCarBookingsApi(credentials);
    //   dispatch({ type: GET_WISH_LIST, payload: credentials });
    return data;
  } catch (error) {
    const errorMessage = error?.message || "Request Failed";
  }
};
export const updateProfile = (credentials) => async (dispatch) => {
  try {
    const data = await updateProfileApi(credentials);
    dispatch({ type: SET_USERDATA, payload: data?.data });
    return data;
  } catch (error) {
    const errorMessage = error?.message || "Request Failed";
  }
};
export const updatePassword = (credentials) => async (dispatch) => {
  try {
    const data = await updatePasswordApi(credentials);
    return data;
  } catch (error) {
    const errorMessage = error?.message || "Request Failed";
  }
};

export const priceEnquiry = (credentials) => async (dispatch) => {
  try {
    const data = await priceEnquiryApi(credentials);
    //   dispatch({ type: GET_WISH_LIST, payload: credentials });
    return data;
  } catch (error) {
    const errorMessage = error?.message || "Request Failed";
  }
};

export const generalSearch = (credentials) => async (dispatch) => {
  try {
    const data = await generalSearchApi(credentials);
    //   dispatch({ type: GET_WISH_LIST, payload: credentials });
    return data;
  } catch (error) {
    const errorMessage = error?.message || "Request Failed";
  }
};

export const getInstagramPosts = (credentials) => async (dispatch) => {
  try {
    const data = await getInstagramPostsApi(credentials);

    dispatch({ type: SET_INSTAGRAM_POSTS, payload: data?.data });
    return data;
  } catch (error) {
    const errorMessage = error?.message || "Request Failed";
  }
};
export const getAllCars = (credentials) => async (dispatch) => {
  try {
    const data = await getAllCarsApi(credentials);
    return data;
  } catch (error) {
    const errorMessage = error?.message || "Request Failed";
  }
};
export const getModifiedCars = (credentials) => async (dispatch) => {
  try {
    const data = await getModifiedCarsApi(credentials);
    return data;
  } catch (error) {
    const errorMessage = error?.message || "Request Failed";
  }
};

export const getDepartments = (credentials) => async (dispatch) => {
  try {
    const data = await getDepartmentsApi(credentials);
    return data;
  } catch (error) {
    const errorMessage = error?.message || "Request Failed";
  }
};

export const startNewChat = (credentials) => async (dispatch) => {
  try {
    const data = await startNewChatApi(credentials);
    return data;
  } catch (error) {
    const errorMessage = error?.message || "Request Failed";
  }
};
export const getPreviousChat = (credentials) => async (dispatch) => {
  try {
    const data = await getPreviousChatApi(credentials);
    return data;
  } catch (error) {
    const errorMessage = error?.message || "Request Failed";
  }
};

export const getMessagesByChatroom = (credentials) => async (dispatch) => {
  try {
    const data = await getMessagesByChatroomApi(credentials);
    return data;
  } catch (error) {
    const errorMessage = error?.message || "Request Failed";
  }
};

export const sendMessage = (credentials) => async (dispatch) => {
  try {
    const data = await sendMessagesApi(credentials);
    return data;
  } catch (error) {
    const errorMessage = error?.message || "Request Failed";
  }
};

export const clearAuthError = () => ({
  type: "CLEAR_AUTH_ERROR",
});
