import styles from "./SearchFilter.module.css";
import React, { useState, useEffect } from "react";
import { getCarMake, getCarModel } from "../redux/auth/authActions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import ReactSelect from "react-select";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
import { RotatingLines } from "react-loader-spinner";
const SearchFilter = ({
  car_make_slug,
  car_model_slug,
  vin,

  isLuxury,
  isAllcars,
}) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location?.search);
  const interiorColorFromProps = searchParams?.get("interiorColor");
  const exteriorColorFromProps = searchParams?.get("exteriorColor");
  // const steering = searchParams?.get("steering");
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [carModel, setCarModel] = useState([]);
  const [carMake, setCarMake] = useState([]);
  const [selectedMakeId, setSelectedMakeId] = useState("");
  const [selectedModelId, setSelectedModelId] = useState("");
  const [selectedModelSlug, setSelectedModelSlug] = useState("");
  const reduxState = useSelector((state) => state.auth);
  const [selectedMakeSlug, setSelectedMakeSlug] = useState("");
  const [isMobile, setIsMobile] = useState(false);
  const [selectedMake, setSelectedMake] = useState("");
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  // const[slectedMakeSlug,setSelectedMakeSlug] = useState("");
  const [interiorColor, setInteriorColor] = useState(
    searchParams?.get("interiorColor") || ""
  );
  const [exteriorColor, setExteriorColor] = useState(
    searchParams?.get("exteriorColor") || ""
  );
  const [colors, setColors] = useState([]);
  const [steering, setSteering] = useState(searchParams?.get("steering") || "");
  const [vin_no, setVin] = useState("");
  const fetchCarModel = async (id) => {
    const response = await dispatch(getCarModel(id));
    setCarModel(response?.data);
  };
  const fetchCarMake = async () => {
    const response = await dispatch(getCarMake());
    setCarMake(response?.data);
    console.log("carmake:", response?.data);
  };
  const onChange = (id) => {
    setSelectedMake(id); // Set selected make
    const selectedCarMak = carMake?.find(
      (item) => parseInt(item.id) === parseInt(id)
    );
    setSelectedMakeSlug(selectedCarMak?.slug);
    fetchCarModel(selectedCarMak?.slug);
  };
  const fetchColors = async () => {
    try {
      const response = await fetch("https://www.csscolorsapi.com/api/colors");

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      setColors(data?.colors);
    } catch (error) {
      console.error("Error fetching colors:", error);
    }
  };
  const handleSearch = (e) => {
    e.preventDefault();
    console.log("handleSearch");
    setIsLoadingButton(true);
    const queryParams = new URLSearchParams();
    if (selectedMakeSlug) {
      queryParams.append("make", selectedMakeSlug);
      if (selectedModelSlug) queryParams.append("model", selectedModelSlug);
      if (interiorColor) queryParams.append("interiorColor", interiorColor);
      if (exteriorColor) queryParams.append("exteriorColor", exteriorColor);
      if (steering) queryParams.append("steering", steering);
      if (vin_no) queryParams.append("vin", vin_no);

      const queryString = queryParams.toString();
      console.log("search params:", queryString);
      if (queryString) {
        // console.log("search params:", queryString);

        navigate(`/car/listing?${queryString}`);
      }
    } else {
      console.log(selectedMakeSlug);
    }
    setIsLoadingButton(false);
  };
  useEffect(() => {
    const detectedLanguage =
      localStorage.getItem("selectedLanguage") ||
      navigator.language.split("-")[0] ||
      "en";
    i18n.changeLanguage(detectedLanguage);
    document.documentElement.lang = detectedLanguage;
    reduxState?.carMake ? setCarMake(reduxState?.carMake) : fetchCarMake();

    // If car_make_slug is present, fetch car models based on the car_make_slug (brand_slug)
    if (car_make_slug) {
      fetchCarModelByBrand(car_make_slug); // Call fetchCarModelByBrand with the brand_slug (car_make_slug)
    }

    // Set the vin, make slug, and model slug if they exist
    if (vin) setVin(vin);
    if (car_make_slug) setSelectedMakeSlug(car_make_slug);
    if (car_model_slug) setSelectedModelSlug(car_model_slug);

    // Fetch colors
    fetchColors();
  }, [
    car_make_slug,
    car_model_slug,
    vin,
    interiorColorFromProps,
    exteriorColorFromProps,
  ]);

  // New effect to run when carModel is updated
  useEffect(() => {
    if (car_model_slug && carModel?.length > 0) {
      const model = carModel?.find((model) => model?.slug === car_model_slug);
      if (model) setSelectedModelSlug(model?.slug);
    }
  }, [carModel, car_model_slug]);

  useEffect(() => {
    if (car_make_slug && carMake?.length > 0) {
      const make = carMake?.find((make) => make.slug === car_make_slug);
      if (make) setSelectedMakeId(make.id);
    }
  }, [carMake, car_make_slug]);

  // This function fetches car models based on the brand slug (car_make_slug)
  const fetchCarModelByBrand = async (brand_slug) => {
    const response = await dispatch(getCarModel(brand_slug));
    setCarModel(response?.data);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600); // Define the breakpoint for mobile
    };

    // Set initial value
    handleResize();

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // useEffect(() => {
  //   console.log('searchfilter', car_make_slug, car_model_slug);

  //   fetchCarMake();
  //   if (car_make_slug) fetchCarModel(car_make_slug);
  //   if (vin) setVin(vin);
  //   if (car_model_slug) setSelectedModelSlug(car_model_slug);
  //   if (car_make_slug) setSelectedMakeSlug(car_make_slug);
  //   if (car_model_slug && carModel?.length > 0) {
  //     console.log(
  //       "hhubho;ub:",
  //       carModel?.find((model) => model?.slug === car_model_slug)
  //     );

  //     const model = carModel?.find((model) => model?.slug === car_model_slug);
  //     if (model) setSelectedModelSlug(model?.slug);
  //   }
  //   if (car_make_slug && carMake.length > 0) {
  //     const make = carMake?.find((make) => make.slug === car_make_slug);
  //     if (make) setSelectedMakeId(make.id);
  //   }

  //   fetchColors();
  // }, []);
  return (
    <>
      <div
        className={`p-2  grey-search-filter ${styles.searchFilter} ${
          isLuxury && "luxury-search-bar"
        }`}
      >
        <div className="row g-2">
          {isAllcars ? (
            <>
              {/* Make Dropdown */}
              <div
                style={{
                  width: isMobile ? "100%" : "14%",
                  marginBottom: isMobile ? "10px" : "0",
                }}
              >
                <select
                  className="form-select"
                  onChange={(e) => onChange(e.target.value)}
                  style={{ backgroundColor: "rgba(204, 204, 204, 0.0)" }}
                >
                  <option selected disabled>
                    {t("make")}
                  </option>
                  {carMake?.map((make) => (
                    <option key={make?.id} value={make?.id}>
                      {make?.name}
                    </option>
                  ))}
                </select>
              </div>

              {/* Model Dropdown */}
              <div
                style={{
                  width: isMobile ? "100%" : "14%",
                  marginBottom: isMobile ? "10px" : "0",
                }}
              >
                <select
                  className="form-select"
                  value={selectedModelSlug}
                  onChange={(e) => setSelectedModelSlug(e.target.value)}
                >
                  <option value="" disabled>
                    {t("model")}
                  </option>
                  {carModel?.map((make) => (
                    <option key={make?.id} value={make?.slug}>
                      {make?.name}
                    </option>
                  ))}
                </select>
              </div>

              {/* Interior Color Dropdown */}
              <div
                style={{
                  width: isMobile ? "100%" : "14%",
                  marginBottom: isMobile ? "10px" : "0",
                }}
              >
                <select
                  className="form-select"
                  value={interiorColor || ""}
                  onChange={(e) => setInteriorColor(e.target.value)}
                >
                  <option value="" disabled>
                    {t("interior_color")}
                  </option>
                  {colors?.map((color) => (
                    <option key={color?.name} value={color?.name}>
                      {color?.name}
                    </option>
                  ))}
                </select>
              </div>

              {/* Exterior Color Dropdown */}
              <div
                style={{
                  width: isMobile ? "100%" : "14%",
                  marginBottom: isMobile ? "10px" : "0",
                }}
              >
                <select
                  className="form-select"
                  value={exteriorColor}
                  onChange={(e) => setExteriorColor(e.target.value)}
                >
                  <option value="" disabled>
                    {t("exterior_color")}
                  </option>
                  {colors?.map((color) => (
                    <option key={color?.name} value={color?.name}>
                      {color?.name}
                    </option>
                  ))}
                </select>
              </div>

              {/* Steering Dropdown */}
              <div
                style={{
                  width: isMobile ? "100%" : "14%",
                  marginBottom: isMobile ? "10px" : "0",
                }}
              >
                <select
                  className="form-select"
                  value={steering}
                  onChange={(e) => setSteering(e.target.value)}
                >
                  <option value="" disabled>
                    {t("steering")}
                  </option>
                  <option value={"LHD"}>{t("lhd")}</option>
                  <option value={"RHD"}>{t("rhd")}</option>
                </select>
              </div>

              {/* VIN Input */}
              <div
                style={{
                  width: isMobile ? "100%" : "14%",
                  marginBottom: isMobile ? "10px" : "0",
                }}
              >
                <input
                  type="text"
                  className="form-control border-0 w-100"
                  placeholder="VIN"
                  value={vin_no}
                  onChange={(e) => setVin(e.target.value)}
                />
              </div>

              {/* Search Button */}
              <div
                style={{
                  width: isMobile ? "100%" : "16%",
                  display: "flex",
                  justifyContent: isMobile ? "center" : "flex-end",
                  marginBottom: isMobile ? "10px" : "0",
                }}
              >
                <button
                  type="button"
                  className="btn btn-primary w-100 h-100 rounded-10"
                  onClick={(e) => handleSearch(e)}
                >
                  {isLoadingButton ? (
                    <RotatingLines width="20" color="white" />
                  ) : (
                    <>
                      <i className="bi bi-search"></i> {t("search")}
                    </>
                  )}
                </button>
              </div>
            </>
          ) : (
            <>
              <div className="col-12 col-sm-6 col-md-6 col-lg-2">
                <select
                  className="form-select"
                  value={selectedModelSlug}
                  onChange={(e) => setSelectedModelSlug(e.target.value)}
                >
                  <option value="" disabled>
                    {t("model")}
                  </option>
                  {carModel?.map((make) => (
                    <option key={make?.id} value={make?.slug}>
                      {make?.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-2">
                <select
                  className="form-select"
                  value={interiorColor || ""}
                  onChange={(e) => setInteriorColor(e.target.value)}
                >
                  <option value="" disabled>
                    {t("interior_color")}
                  </option>
                  {colors?.map((color) => (
                    <option key={color?.name} value={color?.name}>
                      {color?.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-2">
                <select
                  className="form-select"
                  value={exteriorColor}
                  onChange={(e) => setExteriorColor(e.target.value)}
                >
                  <option value="" disabled>
                    {t("exterior_color")}
                  </option>
                  {colors?.map((color) => (
                    <option key={color?.name} value={color?.name}>
                      {color?.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-2">
                <select
                  className="form-select"
                  value={steering}
                  onChange={(e) => setSteering(e.target.value)}
                >
                  <option value="" disabled>
                    {t("steering")}
                  </option>
                  <option value={"LHD"}>{t("lhd")}</option>
                  <option value={"RHD"}>{t("rhd")}</option>
                </select>
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-2">
                <input
                  type="text"
                  className="form-control border-0 w-100"
                  placeholder="  VIN"
                  value={vin_no}
                  onChange={(e) => setVin(e.target.value)}
                />
              </div>

              <div className="col-12 col-sm-6 col-md-6 col-lg-2 d-flex justify-content-end">
                <button
                  type="button"
                  className="btn btn-primary w-100 h-100 rounded-10"
                  onClick={(e) => handleSearch(e)}
                >
                  {isLoadingButton ? (
                    <RotatingLines width="20" color="white" />
                  ) : (
                    <>
                      <i className="bi bi-search"></i> {t("search")}
                    </>
                  )}
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default SearchFilter;
