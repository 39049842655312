import React, {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {signOutAction} from "../redux/auth/authActions";
import {Navigate, Link} from "react-router-dom";
import SecondaryHeader from "../components/SecondaryHeader";
import Footer from "../components/Footer";
import banner from "../assets/images/bank-detail.png";
import RedirectToSignin from "../components/RedirectToSignin";
import RedirectToHome from "../components/RedirectToHome";
import PrimaryMobileHeader from "../components/PrimaryMobileHeader";
import {jsPDF} from "jspdf";
import logo from "../assets/images/white-logo.png"
import BankDetails from "../assets/pdf-files/bank-details.pdf"

const BankAccountDetail = () => {
    const dispatch = useDispatch();
    const [isMobile, setIsMobile] = useState(false);
    const {isAuthenticated} = useSelector((state) => state.auth);

    const handleSignOut = () => {
        dispatch(signOutAction());
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 600); // Define the breakpoint for mobile
        };

        // Set initial value
        handleResize();

        // Add event listener
        window.addEventListener("resize", handleResize);

        // Cleanup the event listener
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const sharePDF = async () => {
        try {
            // Fetch the static PDF file from the assets
            const response = await fetch(BankDetails);
            const pdfBlob = await response.blob();

            // Create a File object from the fetched PDF blob
            const file = new File([pdfBlob], "bank-account-details.pdf", {
                type: "application/pdf",
            });

            // Check if the Web Share API supports sharing files
            if (navigator.canShare && navigator.canShare({ files: [file] })) {
                await navigator.share({
                    title: "Bank Account Details",
                    text: "Here are the bank account details for Cruise Motors FZE.",
                    files: [file],
                });
            } else {
                alert("Web Share API is not supported on this device.");
            }
        } catch (error) {
            console.error("Error sharing the PDF:", error);
            alert("There was an error sharing the PDF.");
        }
    };

    return (
        <div className="bg-white">
            <RedirectToSignin/>
            <RedirectToHome/>
            {!isMobile ? <SecondaryHeader/> : <PrimaryMobileHeader/>}
            <div className="container">
                <div className="servicesPageList mt-4 pb-5">
                    <div className="row justify-content-center">
                        <div className="col-12 col-sm-7">
                            <img src={banner} alt="Banner" className="img-fluid mb-5"/>
                            <h2 className="mb-4 text-center">Bank Account Details</h2>
                            <div className="card rounded-10 mb-5">
                                <div className="card-body">
                                    <table className="table">
                                        <tbody>
                                        {/* First row for the buttons, aligned to the right */}
                                        <tr>
                                            <td colSpan="2">
                                                <div className="d-flex justify-content-end">
                                                    <Link
                                                        to={BankDetails}
                                                        target={"_blank"}
                                                        className="font-20 me-3"
                                                    >
                                                        <i className="bi bi-cloud-download"></i>
                                                    </Link>
                                                    <Link to="#" onClick={() => sharePDF()} className="font-20">
                                                        <i className="bi bi-share"></i>
                                                    </Link>
                                                </div>
                                            </td>
                                        </tr>

                                        {/* Bank details rows */}
                                        <tr>
                                            <td>Bank Website</td>
                                            <td>
                                                <a href="https://www.emiratesnbd.com/en"
                                                   target="_blank">https://www.emiratesnbd.com/en</a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Bank Address</td>
                                            <td>Baniyas Road, Deira, Dubai, UAE</td>
                                        </tr>
                                        <tr>
                                            <td>Account Name</td>
                                            <td>CRUISE MOTORS - FZCO</td>
                                        </tr>
                                        <tr>
                                            <td>SWIFT Code</td>
                                            <td>EBILAEAD</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2}>
                                                <h5 className="m-0"><strong>AED</strong></h5>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Account Number:</td>
                                            <td>1015907925101</td>
                                        </tr>
                                        <tr>
                                            <td>IBAN Number:</td>
                                            <td>AE200260001015907925101</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2}>
                                                <h5 className="m-0"><strong>USD</strong></h5>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Account Number:</td>
                                            <td>1025907925103</td>
                                        </tr>
                                        <tr>
                                            <td>IBAN Number:</td>
                                            <td>AE980260001025907925103</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2}>
                                                <h5 className="m-0"><strong>EUR</strong></h5>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Account Number:</td>
                                            <td>1025907925102</td>
                                        </tr>
                                        <tr>
                                            <td>IBAN Number:</td>
                                            <td>AE280260001025907925102</td>
                                        </tr>
                                        </tbody>
                                    </table>

                                    <a className="theme-color" href="javascript:void(0)">
                                        Terms and Conditions
                                    </a>
                                </div>
                            </div>

                            <p>
                                The above-mentioned bank account is the <strong>only authorized bank
                                account</strong> for Cruise Motors FZCO. Please ensure all payments are made directly to
                                this account. Payments credited to any other account are not the responsibility of
                                Cruise Motors FZCO.
                            </p>
                            <h6 className="fw-bold">
                                Transaction Guidelines:
                            </h6>
                            <ol>
                                <li>
                                    <strong>Proof of Transfer:</strong> Only a Swift Copy MT-103 will be accepted as
                                    valid proof of payment. This ensures a secure and verifiable transaction process.
                                </li>
                                <li>
                                    <strong>Banking Charges:</strong> All banking charges are the responsibility of the
                                    buyer. Please select "Our" or "Self" while processing the transfer to avoid delays
                                    in your transactions.
                                </li>
                                <li>
                                    <strong>Matching Account Name:</strong> The buyer’s name must match the remitter’s
                                    account name. Third-party payments are strictly prohibited unless a payment
                                    authorization form is provided by the client and approved by Cruise Motors FZCO.
                                </li>
                                <li>
                                    <strong>Refund Policy:</strong> Refunds, if applicable, will only be processed to
                                    the original remitting account. We regret to inform you that cash refunds are not
                                    allowed under any circumstances.
                                </li>
                            </ol>
                            <p>
                                <strong>Important Notice:</strong> At Cruise Motors FZCO, we prioritize the safety and
                                transparency of all financial transactions. To avoid any discrepancies or unauthorized
                                payments, we kindly request our clients to strictly follow the above guidelines. This
                                ensures smooth and secure business operations.
                            </p>
                            <p>
                                For any questions or assistance regarding your payments, feel free to contact our
                                support team. We are here to help you every step of the way. Cruise Motors FZCO bank
                                details, official account for payments, secure payment process, Swift Copy MT-103, buyer
                                banking responsibility, refund policy Cruise Motors FZCO, no cash refunds, authorized
                                transactions, vehicle import-export payments, UAE car business transactions.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default BankAccountDetail;
