import React, { useState, useEffect } from "react";
import "../styles/InstagramFeed.css"; // Use this CSS for custom styles
import { Row, Col, Card } from "react-bootstrap"; // Bootstrap components
import { getInstagramPosts } from "../redux/auth/authActions";
import { useDispatch } from "react-redux";
import { FaHeart, FaComment, FaPaperPlane, FaBookmark } from "react-icons/fa";
import logo from "../assets/images/CM LOGO WHITE.png";
const InstagramFeed = () => {
  const [posts, setPosts] = useState([]);
  const [account, setAccount] = useState([]);
  const dispatch = useDispatch();

  const fetchPosts = async () => {
    const response = await dispatch(getInstagramPosts());
    setPosts(response?.posts?.data?.data);
    setAccount(response?.account?.data);
  };

  useEffect(() => {
    fetchPosts();
  }, []);

  return (
    <div className="instagram-feed-container container py-4">
      <div className="instagram-header d-flex align-items-center mb-4">
        <a
          className="d-flex instagram-profile-link"
          href={`https://www.instagram.com/${account?.username}/`}
        >
          <img
            src={logo} // Replace this with your Instagram profile image
            alt="Instagram Profile"
            className="rounded-circle me-3"
            style={{ backgroundColor: "#000740" }}
          />
          <div>
          <div className="d-flex align-items-center">
            <h5 className="mb-0" style={{ color: "black" }}>
              Cruisemotors.co
            </h5>
            <i
              className="bi bi-patch-check-fill text-primary ms-2"
              style={{ fontSize: "18px" }}
            ></i>
            </div>
            <small className="text-muted">@cruisemotors.co</small>
          </div>
        </a>
      </div>

      <Row className="gy-4">
        {" "}
        {/* Responsive grid */}
        {posts?.slice(0, 4)?.map((post) => (
          <Col key={post.id} xs={12} sm={6} md={6} lg={3} className="w-80">
            <Card
              className="h-94 insta-card highlightHover"
              onClick={() => window.open(post.permalink, "_blank")}
              style={{
                cursor: "pointer",
                width: "95%",
                // borderRadius:"20px",
                // backgroundColor: "#e8e8e8",
               height: "100%",
              }}
            >
              <Card.Img
                variant="top"
                src={post.media_url}
                alt="Instagram post"
                className="intagram-image"
              />
              <Card.Body className="" >
                <div className="instagram-icons d-flex justify-content-between mb-2">
                  <div>
                    <FaHeart
                      className="instagram-icon"
                      style={{ color: "red" }}
                    />
                    <FaComment className="instagram-icon" />
                    <FaPaperPlane className="instagram-icon" />
                  </div>
                  <FaBookmark className="instagram-icon" />
                </div>
                <Card.Text className="caption">
                  {post.caption.includes("Contact us")
                    ? post.caption
                        .substring(0, post.caption.lastIndexOf("Contact us"))
                        .trimEnd() + "..." // Trim spaces and add "..."
                    : post.caption.length > 100
                    ? post.caption.substring(0, 100) + "..."
                    : post.caption}
                </Card.Text>
              </Card.Body>
              {/* <Card.Footer>
                <Card.Link
                  href={post.permalink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View Post
                </Card.Link>
              </Card.Footer> */}
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default InstagramFeed;
