import React, { useState, useEffect } from "react";
import ChatRoom from "./ChatRoom";
import { startNewChat, getPreviousChat } from "../redux/auth/authActions";
import ChatWindow from "./ChatWindow"; // Import the new ChatWindow component
import "../styles/ChatBox.css";
import ChatLogo from "../assets/images/white-logo.png";
import { useDispatch, useSelector } from "react-redux";
import { RotatingLines } from "react-loader-spinner"; // Import the loader
import ChatIcon from "../assets/images/website icons/token_chat.png";
const ChatBox = ({ closeChat, setUnRead }) => {
  const dispatch = useDispatch();
  const [chatRooms, setChatRooms] = useState([]);
  const [isChatClosed, setIsChatClosed] = useState(false);
  const [loading, setLoading] = useState(true); // Loading state for the loader
  const [isChatRoomOpen, setIsChatRoomOpen] = useState(false);
  const [selectedChat, setSelectedChat] = useState(null);
  const [newChatStarted, setNewChatStarted] = useState(false);
  const [time, setTime] = useState("");
  const getChatRooms = async () => {
    setLoading(true); // Start the loader
    const response = await dispatch(getPreviousChat());
    console.log(response?.data);
    setLoading(false); // Stop the loader once data is fetched
    if (Array.isArray(response?.data)) {
      setChatRooms(response?.data);
      if (response?.data?.length > 0) {
        for (const room of response?.data) {
          if (room?.has_unread_messages === true) {
            setUnRead(true);
            break;
          }
        }
      }
    }
    setLoading(false); // Stop the loader once data is fetched
  };

  const openChatRoom = () => {
    setIsChatRoomOpen(true);
    setNewChatStarted(true); // Indicate a new chat is starting
  };

  const openPreviousChat = (chat) => {
    setSelectedChat(chat); // Open previous chat directly
    setIsChatRoomOpen(true); // Ensure the chat window is open
    setNewChatStarted(false); // It's a previous chat, not a new one
  };

  const handleInquirySubmit = async (inquiry, department) => {
    const inquiryMessages = [{ text: inquiry, from: "User" }];
    const response = await dispatch(
      startNewChat({ department_id: department?.id, message: inquiry })
    );
    setSelectedChat({
      department,
      messages: inquiryMessages,
      id: response?.data?.chat_room?.id,
    });
    setIsChatRoomOpen(true);
    setNewChatStarted(false);
  };

  useEffect(() => {
    getChatRooms();

    return () => {
      setIsChatClosed(true);
    };
  }, [dispatch]);

  const handleCloseChat = () => {
    setIsChatClosed(true);
    isChatRoomOpen & !newChatStarted && setUnRead(false);
    closeChat();
  };

  useEffect(() => {
    // Function to update the time
    const updateTime = () => {
      const now = new Date();
      const hours = now.getHours();
      const minutes = now.getMinutes();
      const ampm = hours >= 12 ? "PM" : "AM";
      const formattedTime = `${hours % 12 || 12}:${
        minutes < 10 ? `0${minutes}` : minutes
      } ${ampm}`;
      setTime(formattedTime);
    };

    updateTime(); // Set initial time
    const interval = setInterval(updateTime, 1000); // Update time every second

    return () => clearInterval(interval); // Clean up the interval on component unmount
  }, []);

  return (
    <>
      <div className="chat-box card shadow-sm position-fixed mb-3">
        {/* Show Chat Room for new chat or previous chat */}
        <button
          className="btn p-0 position-absolute"
          onClick={handleCloseChat} // Replace with your close function
          style={{
            zIndex: "9999",
            bottom: "633px", // Positioning at the top of the container
            right: "15px", // Positioning to the right of the container
            backgroundColor: "blue", // No background for the button
            border: "none", // No border
            cursor: "pointer",
          }}
          aria-label="Close"
        >
          <i
            className="bi bi-x-lg"
            style={{
              fontSize: "24px", // Adjust icon size
              color: "#fff", // White color for the close button
            }}
          ></i>
        </button>
        {isChatRoomOpen ? (
          newChatStarted ? (
            <>
              {/* <div className="chat-header card-header ">
                <div className="col-4">
                  {" "}
                  <img
                    src={ChatLogo}
                    alt="Chat Logo"
                    className="chat-logo me-2"
                  />
                </div>
                <h5 className="mb-0">Welcome to Our Customer Services!</h5>
                <div className="col-1"></div>
                <div className="col-1">
                </div>
              </div> */}
              <ChatRoom
                onSubmit={handleInquirySubmit}
                close={handleCloseChat}
              />
            </>
          ) : (
            <ChatWindow
              initialMessages={selectedChat?.messages || []}
              department={selectedChat?.department || "No Department"}
              chatRoomId={selectedChat?.id}
              close={handleCloseChat}
            />
          )
        ) : (
          <>
            <button
              className="btn p-0 position-absolute"
              onClick={handleCloseChat} // Replace with your close function
              style={{
                top: "7px", // Positioning at the top of the container
                right: "15px", // Positioning to the right of the container
                background: "transparent", // No background for the button
                border: "none", // No border
                cursor: "pointer",
              }}
              aria-label="Close"
            >
              <i
                className="bi bi-x-lg"
                style={{
                  fontSize: "24px", // Adjust icon size
                  color: "#fff", // White color for the close button
                }}
              ></i>
            </button>
            <div className="chat-header  text-white p-3 text-center">
              {/* Logo */}

              <div className="d-flex mb-4">
                <img
                  src={ChatLogo} // Replace with your actual logo path
                  alt="Chat Logo"
                  className="chat-logo me-2"
                />
              </div>

              {/* Welcome Text */}
              <h5 className="mb-3 fw-bold text-start ms-3">
                Welcome to Our Customer Services!
              </h5>

              {/* Chat Info Section */}
              <div
                className="card shadow-sm card-need-help bg-white"
                style={{
                  left: "47.5%",
                  transform: "translateX(-50%)",
                }}
              >
                <div className="card-body d-flex text-start justify-content-between">
                  <div>
                    <p className="mb-1 text-dark fw-bold">
                      Need help? We’re here to assist!
                    </p>
                    <hr />
                    <div className="d-flex">
                      <img
                        src={ChatIcon}
                        alt="Chat Icon"
                        className="me-2"
                        style={{ height: "24px", width: "24px" }}
                      />
                      <div>
                        <p className="mb-0 small">
                          <strong>Chat with us</strong>
                        </p>
                        <p
                          className="mb-0 text-muted small"
                          style={{ fontSize: "0.65em" }}
                        >
                          Hello welcome to cruisemotors.com
                        </p>
                      </div>
                      <span
                        className="text-muted small"
                        style={{ fontSize: "0.7em" }}
                      >
                        {time}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="chat-body card-body overflow-auto bg-white"
              style={{ maxHeight: "300px" }}
            >
              {loading ? (
                // Display loader when loading is true
                <div className="d-flex justify-content-center align-items-center">
                  <RotatingLines
                    strokeColor="#000740"
                    strokeWidth="5"
                    animationDuration="0.75"
                    width="50"
                    visible={true}
                  />
                </div>
              ) : (
                <>
                  {chatRooms?.length > 0 ? (
                    // Display chat rooms when they exist
                    <>
                      <p className="fw-bold custom-bold">
                        Select a previous chat to continue or start a new one:
                      </p>
                      <ul className="list-group d-flex">
                        {chatRooms?.map((chat) => (
                          <li
                            key={chat?.id}
                            className="d-flex list-group-item mb-2  rounded-3 shadow-sm bg-white"
                            onClick={() => openPreviousChat(chat)}
                            style={{ borderTopWidth: "1" }}
                          >
                            <div className="ms-2 me-auto">
                              <div className="fw-bold">
                                {chat?.department?.name}
                              </div>
                              <small>{chat?.message}</small>
                            </div>
                            <div className="d-flex  align-items-end mt-2">
                              <span className="badge rounded-pill">
                                {new Date(chat?.updated_at).toLocaleDateString(
                                  "en-US",
                                  {
                                    year: "numeric",
                                    month: "long",
                                    day: "numeric",
                                  }
                                )}
                              </span>
                              <div>
                                {chat?.unread_messages_count > 0 && (
                                  <small className="">
                                    {chat?.unread_messages_count}
                                  </small>
                                )}
                                <i className="bi bi-chat ms-2"></i>
                              </div>
                              {chat?.has_unread_messages && (
                                <span
                                  className="badge bg-danger"
                                  style={{
                                    position: "absolute",
                                    top: "-4px",
                                    right: "0px",
                                    borderRadius: "50%",
                                    width: "15px",
                                    height: "15px",
                                    color: "red",
                                  }}
                                >
                                  .
                                </span>
                              )}
                            </div>
                          </li>
                        ))}
                      </ul>
                    </>
                  ) : (
                    // Display fallback message when no chat rooms exist
                    <div className="text-center mt-3">
                      <p>No previous chats found.</p>
                    </div>
                  )}
                </>
              )}
            </div>
          </>
        )}

        {!isChatRoomOpen && (
          <div className="chat-footer card-footer d-flex justify-content-center">
            <button
              className="btn btn-primary"
              type="button"
              onClick={openChatRoom}
              style={{ borderRadius: "10px" }}
            >
              Start New Chat
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default ChatBox;
