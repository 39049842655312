import "../components/PrimaryHeader.css";
import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { signOutAction } from "../redux/auth/authActions";
import {
  setCurrentLanguage,
  getBrandList,
  getFeatureBrandList,
} from "../redux/auth/authActions";
import { Navigate, Link, useNavigate } from "react-router-dom";
import LanguageSelect from "./LanguageSelect";
import dataArray from "../components/GetArray";
import logo from "../assets/images/white-logo.png"; // Import the image file
import bc1 from "../assets/images/bc1.png";
import bc2 from "../assets/images/bc2.png";
import bc3 from "../assets/images/bc3.png";
import { API_URL } from "../api/authApi";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
import bluelogo from "../assets/images/blue-logo.png";
const HeaderMenu = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { isAuthenticated, user } = useSelector((state) => state.auth);
  const menuItems = dataArray.getMainMenu(t);
  const [brands, setBrands] = useState([]);
  const [hoveredBrand, setHoveredBrand] = useState(null);
  const reduxState = useSelector((state) => state.auth);
  const [language, setLanguage] = useState(
    localStorage.getItem("selectedLanguage") ||
      navigator.language.split("-")[0] ||
      "en"
  );
  const api_url = API_URL.replace("/api", "");
  const fetchFeaturedBrands = async () => {
    const response = await dispatch(getFeatureBrandList(1));
    setBrands(response?.data);
  };
  const userData = user?.data?.user || {};
  const [isDropdownOpen, setDropdownOpen] = useState(null);
  // Hover handlers
  const handleMouseEnter = (index, e) => {
    e.preventDefault();
    setDropdownOpen(index);
  };

  const handleMouseLeave = () => {
    setDropdownOpen(null);
  };

  // Handle navigation on click
  const handleNavigation = (path,e) => {
    e.preventDefault();
    console.log("djcvnds.jkcbdskj.cbdsjkcb:", path);

    const restrictedPaths = [
      "/services",
      "/logistics",
      "/payment",
      "/payment-method",
      "/brands",
    ];
    if (!path.startsWith("/")) {
      path = "/" + path;
    }
    if (window.innerWidth <= 1400 && restrictedPaths.includes(path)) {
      console.log("inside ifffffff");

      // e.preventDefault(); // Prevent navigation
      return; // Exit the function
    }
    console.log("dncjidbncidbcdihbcdhibcdhibc");

    navigate(path);
  };
  const handleAllcarsNavigation = (e) => {
    e.preventDefault();
    const queryParams = new URLSearchParams();
    queryParams.append("cars", "all-cars");
    const queryString = queryParams.toString();
    navigate(`/all-cars`);
  };
  const handleModifiedCarNavigation = (e) => {
    e.preventDefault();
    const queryParams = new URLSearchParams();
    queryParams.append("cars", "all-cars");
    const queryString = queryParams.toString();
    navigate(`/modified-cars`);
  };
  const handleBrandNavigation = (slug, e) => {
    e.preventDefault();

    console.log("Brand slug:", slug);

    if (slug) {
      const queryParams = new URLSearchParams();
      queryParams.append("make", slug);
      const queryString = queryParams.toString();
      navigate(`/car/listing?${queryString}`);
    } else {
      console.error("Error: Slug is missing or invalid.");
    }
  };
  const handleCarNavigation = (car_slug, brand_slug, e) => {
    e.preventDefault();

    const queryParams = new URLSearchParams();
    car_slug && queryParams.append("model", car_slug);
    brand_slug && queryParams.append("make", brand_slug);
    const queryString = queryParams.toString();
    navigate(`/car/listing?${queryString}`);
  };
  useEffect(() => {
    reduxState?.featuredBrandsWithCars
      ? setBrands(reduxState?.featuredBrandsWithCars)
      : fetchFeaturedBrands();
    // Detect and set the default language based on the browser or localStorage
    const detectedLanguage =
      localStorage.getItem("selectedLanguage") ||
      navigator.language.split("-")[0] ||
      "en";
    i18n.changeLanguage(detectedLanguage);
    document.documentElement.lang = detectedLanguage;
  }, []);

  const handleSignOut = () => {
    console.log("Sign out");

    localStorage.removeItem("authToken");
    dispatch(signOutAction());
  };

  if (!isAuthenticated) {
    return <Navigate to="/signin" />;
  }

  const handleLanguageChange = (lang) => {
    i18n.changeLanguage(lang);
    // localStorage.setItem('selectedLanguage', lang);
    document.documentElement.lang = lang;
    setLanguage(lang);
    dispatch(setCurrentLanguage(lang));
  };

  const handleTrackOrder = () => {
    navigate("/track-order"); // Adjust the path as needed
  };
  const truncateName = (name) => {
    if (!name) return "";

    const firstSpaceIndex = name.indexOf(" ");

    // If the first part of the name is greater than 6 characters, truncate it
    if (firstSpaceIndex !== -1 && firstSpaceIndex <= 6) {
      return name.substring(0, firstSpaceIndex);
    }

    // If no space or name part exceeds 6 characters, truncate at 6 characters
    return name?.length > 6 ? `${name.substring(0, 6)}...` : name;
  };

  return (
    <>
      <nav className="navbar navbar-expand-xxl">
        <div className="container">
          <Link className="navbar-brand" to="/">
            <img src={logo} alt="Logo" width="100" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarText"
            aria-controls="navbarText"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon">
              <i class="bi bi-list text-white font20"></i>
            </span>
          </button>
          <div className="collapse navbar-collapse ms-4" id="navbarText">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0 header-menu-ul">
              <li
                className="nav-item dropdown ps-2 pe-2"
                onMouseEnter={(e) => handleMouseEnter("brands", e)}
                onMouseLeave={handleMouseLeave}
              >
                <a
                  className="nav-link text-white d-flex"
                  id="brandsDropdown"
                  role="button"
                  aria-expanded="false"
                  style={{ position: "relative" }}
                  onClick={(e) => handleNavigation("brands",e)}
                >
                  {t("brand_heading")}
                </a>
                <ul
                  className="dropdown-menu"
                  style={{
                    display: isDropdownOpen === "brands" ? "block" : "none",
                  }}
                  aria-labelledby="brandsDropdown"
                >
                  <li
                    className="ms-2 d-flex align-items-center"
                    style={{ height: "30px", position: "relative" }}
                    onClick={(e) => handleAllcarsNavigation(e)}
                  >
                    {/* External link to official website */}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      className="me-2"
                      style={{ cursor: "pointer" }}
                    >
                      <i
                        className="fa fa-car-side ms-1 me-1"
                        style={{ color: "#000740", fontSize: "18px" }}
                      ></i>
                      {/* <img
                        src={bluelogo}
                        style={{ width: "30px", height: "12px" }}
                      /> */}
                    </a>

                    {/* Internal navigation for the brand */}
                    <Link
                      className="nav-link d-inline text-dark dropdown-item d-flex"
                      to="#"
                      // onClick={(e) => handleAllcarsNavigation(e)} // Call handleBrandNavigation for brand routing
                    >
                      All Cars
                    </Link>

                    {/* Nested dropdown for cars */}
                  </li>
                  <li
                    className="ms-2 d-flex align-items-center"
                    style={{ height: "26px", position: "relative" }}
                    onClick={(e) => handleModifiedCarNavigation(e)}
                  >
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      className="me-2"
                    >
                      <i
                        className="fa fa-cogs  ms-1 me-1"
                        style={{ color: "#000740", fontSize: "18px" }}
                      ></i>
                    </a>

                    <Link
                      className="nav-link d-inline text-dark dropdown-item d-flex"
                      to="#"
                    >
                      Modified Cars
                    </Link>
                  </li>

                  {brands?.map((brand) => (
                    <li
                      key={brand.id}
                      style={{
                        height: "30px",
                        position: "relative",
                        width: "190px",
                      }}
                      onMouseEnter={() => {
                        setHoveredBrand(brand.id);
                      }}
                      onMouseLeave={() => setHoveredBrand(null)}
                    >
                      <div className="ms-2 d-flex align-items-center">
                        {/* External link to official website */}
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          className="me-2"
                          style={{ cursor: "pointer" }}
                          onClick={(e) => {
                            e.stopPropagation(); // Prevent bubbling to parent elements
                            handleBrandNavigation(brand?.slug, e);
                          }}
                        >
                          <img
                            src={`${api_url}/${brand?.logo?.path}`} // Append api_url with logo path
                            alt={brand.name}
                            style={{ width: "30px", height: "30px" }}
                          />
                        </a>

                        {/* Internal navigation for the brand */}
                        <Link
                          className="nav-link d-inline text-dark dropdown-item d-flex"
                          to="#"
                          onClick={(e) => handleBrandNavigation(brand?.slug, e)} // Call handleBrandNavigation for brand routing
                        >
                          {brand.name}
                        </Link>
                      </div>

                      {/* Nested dropdown for cars */}
                      {hoveredBrand === brand?.id &&
                        brand?.carModels?.length > 0 && (
                          <ul
                            className="dropdown-menu"
                            style={{
                              display: "block",
                              position: "absolute",
                              top: 0,
                              left: "100%", // Align this dropdown to the right of the parent dropdown
                              zIndex: 1000, // Ensure the nested dropdown stays on top
                              minWidth: "300px", // Optional: Adjust the width of the car dropdown
                              maxHeight: "600px", // Limit the dropdown height
                              overflowY: "auto", // Make it scrollable
                            }}
                          >
                            {brand?.carModels?.map((car) => (
                              <li key={car?.id} style={{ height: "30px" }}>
                                <Link
                                  className="dropdown-item d-flex align-items-center"
                                  to="#"
                                  onClick={
                                    (e) =>
                                      handleCarNavigation(
                                        car?.slug,
                                        brand?.slug,
                                        e
                                      ) // Call handleCarNavigation for car routing
                                  }
                                >
                                  {car?.name?.length > 25
                                    ? `${car.name.substring(0, 25)}...`
                                    : car?.name}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        )}
                    </li>
                  ))}
                </ul>
              </li>

              {/* Other menu items */}
              {menuItems?.map((item, index) => (
                <li
                  className={`nav-item ${
                    item?.children && item?.children?.length > 0
                      ? "dropdown"
                      : ""
                  } ps-2 pe-2`}
                  key={item?.key}
                  onMouseEnter={(e) => handleMouseEnter(index, e)}
                  onMouseLeave={handleMouseLeave}
                >
                  <Link
                    className="nav-link text-white d-flex"
                    onClick={(e) => handleNavigation(item?.path,e)}
                  >
                    {item?.label}
                  </Link>

                  {/* Dropdown for submenu */}
                  {item?.children && item?.children?.length > 0 && (
                    <ul
                      className="dropdown-menu"
                      style={{
                        display: isDropdownOpen === index ? "block" : "none",
                      }}
                    >
                      {item?.children?.map((child) => (
                        <li key={child.key}>
                          <Link
                            className="dropdown-item d-flex"
                            to={child?.path}
                            onClick={(e) => handleNavigation(child?.path,e)}
                          >
                            {child?.icon &&
                              React.cloneElement(child?.icon, {
                                className: `${child.icon.props.className} me-2`, // Add more classes dynamically
                                style: { color: "#282c34" }, // Apply styles dynamically
                              })}
                            {/* <i
                              className={`${child?.icon} me-2`}
                              style={{ color: "#282c34" }}
                            ></i> */}
                            {child?.label}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>

            <span className="navbar-text d-flex">
              <button
                type="button"
                className="btn btn-outline-primary me-2 bg-white text-dark font-13"
                onClick={handleTrackOrder}
              >
                <i class="bi bi-geo-alt me-2"></i>
                {t("track_order")}
              </button>
              <div class="dropdown me-2">
                <button
                  className={` font-19 ${
                    userData?.is_verified
                      ? "btn-outline-success bg-light text-success"
                      : "btn-outline-primary  text-dark"
                  }`}
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style={{ backgroundColor: "transparent", border: "none" }}
                >
                  <i
                    className={`bi ${
                      userData?.is_verified
                        ? "bi-person  text-success me-2"
                        : "bi-person "
                    }`}
                    style={{ color: "white" }}
                  ></i>
                  {/* {t("hi")} {truncateName(userData?.name)} */}
                  {userData?.is_verified && (
                    <i
                      className="bi bi-patch-check-fill ms-2 text-success "
                      style={{ color: "white" }}
                    ></i>
                    // Verified badge icon
                  )}
                </button>
                <ul
                  class="dropdown-menu"
                  style={{
                    paddingTop: "0px",
                    left: "-158px",
                    minWidth: "215px",
                  }}
                >
                  <li
                    style={{ backgroundColor: "#efefef", marginBottom: "1rem" }}
                  >
                    <Link className="dropdown-item" to="/account">
                      <div>
                        <span>
                          {t("hi")} {userData?.name}
                        </span>
                        <br />
                        <small className="text-muted">Welcome back</small>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/account">
                      <i className="bi bi-person me-2"></i>

                      {t("my_account")}
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/car-bookings">
                      {" "}
                      {/* {t("orders")} */}
                      <i className="bi bi-card-list me-2"></i>
                      Orders
                    </Link>
                  </li>
                  <li>
                    <div className="dropdown-item" to="#">
                      {" "}
                      {/* {t("orders")} */}
                      <i className="bi bi-arrow-repeat me-2"></i>
                      Return Request
                    </div>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/wishlist">
                      {" "}
                      {/* {t("orders")} */}
                      <i className="bi bi-heart me-2"></i>
                      Wishlist
                    </Link>
                  </li>
                  {/* <li>
                    <hr class="dropdown-divider" />
                  </li> */}
                  <li>
                    <Link className="dropdown-item" onClick={handleSignOut}>
                      <i className="bi bi-box-arrow-right me-2"></i>
                      {t("logout")}
                    </Link>
                  </li>
                </ul>
              </div>

              <LanguageSelect
                selectedLanguage={language}
                onLanguageChange={handleLanguageChange}
              />
            </span>
          </div>
        </div>
      </nav>
    </>
  );
};

export default HeaderMenu;
