import React, { useState, useEffect } from "react";
import "./PrimaryMobileHeader.css";
import i18n from "../i18n"; // Import your i18n configuration
import { useDispatch, useSelector } from "react-redux";
import { signOutAction } from "../redux/auth/authActions";
import {
  setCurrentLanguage,
  getBrandList,
  getFeatureBrandList,
} from "../redux/auth/authActions";
import { Navigate, Link, useNavigate } from "react-router-dom";
import {
  Button,
  Drawer,
  Radio,
  Divider,
  List,
  Typography,
  Dropdown,
  Space,
} from "antd";
import logo from "../assets/images/blue-logo.png";
import whitelogo from "../assets/images/white-logo.png";
import {
  AppstoreOutlined,
  MailOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { Menu } from "antd";
import LanguageSelect from "./LanguageSelect";
import dataArray from "../components/GetArray";
import { useTranslation } from "react-i18next";
import { API_URL } from "../api/authApi";
const data = [
  "Racing car sprays burning fuel into crowd.",
  "Japanese princess to wed commoner.",
  "Australian walks 100km after outback crash.",
  "Man charged over missing wedding girl.",
  "Los Angeles battles huge wildfires.",
];

const PrimaryMobileHeader = ({ isBlack }) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [brands, setBrands] = useState([]);
  const { isAuthenticated, user } = useSelector((state) => state.auth);
  const reduxState = useSelector((state) => state.auth);
  const api_url = API_URL.replace("/api", "");
  // const menuItems = dataArray.getMainMenu(t);
  const fetchFeaturedBrands = async () => {
    const response = await dispatch(getFeatureBrandList(1));
    setBrands(response?.data);
  };
  const menuItems = [
    ...dataArray.getMainMenu(t),
    {
      key: 7,
      label: t("menu.my_account"),
      path: "/account",
    },
  ];

  const dynamicMenuItems = [
    {
      key: "brands",
      label: "Brands",
      children: brands.map((brand) => ({
        key: brand.id,
        label: (
          <div className="d-flex align-items-center">
            <img
              src={`${api_url}/${brand?.logo?.path}`}
              alt={brand?.name}
              style={{ width: "30px", height: "30px", marginRight: "8px" }}
            />
            {brand?.name}
          </div>
        ),
        children: brand?.carModels?.map((car) => ({
          key: car.id,
          label: car.name,
          path: `/car/listing?make=${brand.slug}&model=${car.slug}`,
        })),
      })),
    },
    ...menuItems, // Append other existing menu items
  ];

  const userData = user?.data?.user || {};
  const [language, setLanguage] = useState(
    localStorage.getItem("selectedLanguage") ||
      navigator.language.split("-")[0] ||
      "en"
  );
  useEffect(() => {
    // Detect and set the default language based on the browser or localStorage
    const detectedLanguage =
      localStorage.getItem("selectedLanguage") ||
      navigator.language.split("-")[0] ||
      "en";
    reduxState?.featuredBrandsWithCars
      ? setBrands(reduxState?.featuredBrandsWithCars)
      : fetchFeaturedBrands();
    i18n.changeLanguage(detectedLanguage);
    document.documentElement.lang = detectedLanguage;
  }, []);
  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState("left"); // Set as string
  const showDrawer = () => {
    setOpen(true);
  };
  const truncateName = (name) => {
    if (!name) return "";

    const firstSpaceIndex = name.indexOf(" ");

    // If the first part of the name is greater than 6 characters, truncate it
    if (firstSpaceIndex !== -1 && firstSpaceIndex <= 6) {
      return name.substring(0, firstSpaceIndex);
    }

    // If no space or name part exceeds 6 characters, truncate at 6 characters
    return name?.length > 6 ? `${name.substring(0, 6)}...` : name;
  };
  const onClose = () => {
    setOpen(false);
  };

  const onChange = (e) => {
    setPlacement(e.target.value); // No need for DrawerProps
  };

  const handleSignOut = () => {
    localStorage.removeItem("authToken");
    dispatch(signOutAction());
  };

  if (!isAuthenticated) {
    return <Navigate to="/signin" />;
  }

  const handleLanguageChange = (lang) => {
    i18n.changeLanguage(lang);
    document.documentElement.lang = lang;
    setLanguage(lang);
    dispatch(setCurrentLanguage(lang));
  };

  const handleTrackOrder = () => {
    navigate("/track-order"); // Adjust the path as needed
  };

  const handleClick = (e) => {
    const clickedItemKey = e.key; // Get the clicked item's key
    const clickedItem = findItemByKey(dynamicMenuItems, clickedItemKey);
    console.log(clickedItem, clickedItemKey, "clickedItem");

    if (clickedItem && clickedItem.path) {
      navigate(clickedItem.path); // Navigate to the path of the clicked item
    }
  };

  const findItemByKey = (items, key) => {
    for (let item of items) {
      if (item.key == key) {
        return item;
      }

      if (item.children) {
        const foundChild = findItemByKey(item.children, key);
        if (foundChild) return foundChild;
      }
    }
    return null;
  };

  return (
    <>
      <div className="header sticky-top">
        <div
          className="header-inner d-flex justify-content-between align-items-center ps-2 pe-3 pt-2 pb-2 shadow"
          style={{
            backgroundColor: isBlack ? "black" : "white",
          }}
        >
          <div className="d-flex justify-content-between align-items-center">
            <span type="primary" onClick={showDrawer}>
              <i class="bi bi-list font-30 text-muted p-3"></i>
            </span>
            <Link className="" to="/">
              <img src={isBlack ? whitelogo : logo} alt="Logo" width="80" />
            </Link>
          </div>
          <LanguageSelect
            selectedLanguage={language}
            onLanguageChange={handleLanguageChange}
          />
        </div>

        <Drawer
          title={
            <div className="d-flex justify-content-between align-items-center ps-2">
              <div>
                <div>
                  <h6 className="m-0 text-white">
                    {t("hi")} {userData?.name}
                  </h6>
                  <p className="m-0 font-13 text-white fw-light">
                    {t("welcome")}
                  </p>
                </div>
              </div>
            </div>
          } // Custom header content
          placement={placement}
          closable={false}
          onClose={onClose}
          open={open}
          style={{ padding: "0px" }}
          key={placement}
          width={300}
          className="menuDrawer"
          footer={
            <div className="d-flex justify-content-between align-items-center">
              <Button
                style={{ borderColor: "#000740", color: "#000740" }}
                className="w-100"
                onClick={handleTrackOrder}
                primary
              >
                <i class="bi bi-geo-alt"></i>
                {t("track_order")}
              </Button>
              <Button
                style={{ borderColor: "#000740", color: "#000740" }}
                className="w-100 ms-2"
                onClick={handleSignOut}
              >
                <i class="bi bi-box-arrow-right"></i>
                {t("logout")}
              </Button>
            </div>
          }
        >
          <Menu
            onClick={handleClick}
            // style={{ width: 256 }}
            defaultSelectedKeys={["1"]}
            defaultOpenKeys={["sub1"]}
            mode="inline"
            items={dynamicMenuItems}
          />
        </Drawer>
      </div>
    </>
  );
};

export default PrimaryMobileHeader;
