import React, { useState, useEffect } from "react";
import chatIcon from "../assets/images/website icons/chat.png";
import ChatBox from "./ChatBox";
const ChatButton = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [unRead, setUnRead] = useState(false);
  const toggleChatMenu = () => {
    setIsExpanded(!isExpanded);
  };
  const toggleChat = () => {
    setIsChatOpen(!isChatOpen);
  };
  //   useEffect(() => {
  //     setUnRead(unread);
  //   }, [unRead, unread]);
  const toggleWhatsapp = () => {
    const phoneNumber = "971561331109"; // Phone number in international format (without + or spaces)
    const whatsappLink = `https://wa.me/${phoneNumber}`; // Generates a WhatsApp link for the specified number
    console.log(whatsappLink);

    window.open(whatsappLink, "_blank"); // Opens the link in a new tab or window
  };

  return (
    <div
      style={{
        position: "fixed",
        bottom: "20px",
        right: "20px",
        zIndex: 1000,
      }}
    >
      {/* Expanded Menu */}
      {isExpanded && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <button
            onClick={() => {
              window.open("mailto:Info@cruisemotors.co", "_blank");
            }}
            style={{
              backgroundColor: "#000740",
              color: "white",
              border: "none",
              borderRadius: "50%",
              width: "50px",
              height: "50px",
              marginBottom: "10px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              cursor: "pointer",
            }}
          >
            <i
              className="bi bi-envelope-fill"
              style={{ fontSize: "20px", color: "white" }}
            ></i>
          </button>
          <button
            onClick={toggleChat}
            style={{
              backgroundColor: "#000740",
              color: "white",
              border: "none",
              borderRadius: "50%",
              width: "50px",
              height: "50px",
              marginBottom: "10px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              cursor: "pointer",
            }}
          >
            <img
              src={chatIcon}
              alt="Chat"
              style={{ width: "20px", height: "20px" }}
            />
          </button>
          <button
            onClick={toggleWhatsapp}
            style={{
              backgroundColor: "green",
              color: "white",
              border: "none",
              borderRadius: "50%",
              width: "50px",
              height: "50px",
              marginBottom: "10px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              cursor: "pointer",
            }}
          >
            <i className="bi bi-whatsapp" style={{ fontSize: "22px" }}></i>
          </button>
        </div>
      )}

      {/* Main Chat Button */}
      <button
        onClick={toggleChatMenu}
        style={{
          backgroundColor: isExpanded ? "black" : "#000740",
          color: "white",
          border: "none",
          borderRadius: "50%",
          width: "60px",
          height: "60px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
          cursor: "pointer",
        }}
      >
        {isExpanded ? (
          <i
            className="bi bi-x"
            style={{
              fontSize: "28px", // Increased font size for the icon
            }}
          ></i>
        ) : (
          <i
            className="bi bi-chat-dots-fill"
            style={{
              fontSize: "28px", // Increased font size for the icon
            }}
          ></i>
        )}
      </button>
      {isChatOpen && (
        <div className={`chat-box-wrapper ${isChatOpen ? "chat-open" : ""}`}>
          <ChatBox closeChat={toggleChat} setUnRead={setUnRead} />
        </div>
      )}
    </div>
  );
};

export default ChatButton;
