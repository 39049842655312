import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  signOutAction,
  submitSupplyContractForm,
  updatePassword
} from "../redux/auth/authActions";
import { Navigate, Link, Router, Route, Routes } from "react-router-dom";
import SecondaryHeader from "../components/SecondaryHeader";
import Footer from "../components/Footer";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Button, message, Upload } from "antd";
import LanguageSelect from "../components/LanguageSelect";
import i18n from "../i18n";
import banner from "../assets/images/warranty-information.png";
import RedirectToSignin from "../components/RedirectToSignin";
import RedirectToHome from "../components/RedirectToHome";
import PrimaryMobileHeader from "../components/PrimaryMobileHeader";
import UserDashboardSideMenu from "../components/UserDashboardSideMenu";
import InputField from "../components/InputField";
import { useTranslation } from "react-i18next";

const Password = () => {
  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector((state) => state.auth);
  const [isMobile, setIsMobile] = useState(false);
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    // old_password: "",
    new_password: "",
    confirm_password: "",
    mobile: "",
    person_designation: "",
    company_address: "",
  });

  const [uploadedFiles, setUploadedFiles] = useState({
    tradeLicense: null,
    vehicleSpecs: null,
    purchaseOrder: null,
    letterOfIntent: null,
  });

  const [fileList, setFileList] = useState({
    tradeLicense: [],
    purchaseOrder: [],
  });

  const [errors, setErrors] = useState({});
  const [charCount, setCharCount] = useState(0);
  const charLimit = 200;
  const allowedFileTypes = [
    "application/pdf",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "image/jpeg",
    "image/png",
  ];
  const validate = () => {
    let formErrors = {};

    // if (!formData.old_password)
    //   formErrors.old_password = "Please enter old password!";
    if (!formData.new_password)
      formErrors.new_password = "Please enter new password!";
    
    if (!formData.confirm_password)
      formErrors.confirm_password = "Please enter confirm password!";
    if(formData.new_password !== formData.confirm_password)
      formErrors.confirm_password = t("passwords_do_not_match")
    return formErrors;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formErrors = validate();

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }

    const data = new FormData(); // Create FormData object
    // data.append("old_password", formData.old_password);
    data.append("password", formData.new_password);
    data.append("password_confirmation", formData.confirm_password);

    const response = await dispatch(updatePassword(data));
    if (response?.code == 200) {
      message.success("Submitted successfully!");
    } else {
      message.error(response?.message || "Submission failed!");
    }
  };

  const handleSignOut = () => {
    dispatch(signOutAction());
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600); // Define the breakpoint for mobile
    };
    const detectedLanguage =
    localStorage.getItem("selectedLanguage") ||
    navigator.language.split("-")[0] ||
    "en";
  i18n.changeLanguage(detectedLanguage);
  document.documentElement.lang = detectedLanguage;
    // Set initial value
    handleResize();

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div>
      <RedirectToSignin />
      <RedirectToHome />
      {!isMobile ? <SecondaryHeader /> : <PrimaryMobileHeader />}
      <div className="container">
        <div className="tendorsPage mt-4 pb-5">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-12">
              <h3 className="mb-4">My Dashboard</h3>
              <div className="row">
              <div className="col-12 col-md-3 mb-4">
                  {" "}
                  {/* Adjust column sizes for mobile */}
                  <UserDashboardSideMenu />
                </div>
                <div className="col-12 col-md-9">
                  <div className="card bg-white border-0 rounded-10">
                    <div className="card-body">
                      <form onSubmit={handleSubmit}>
                        <div className="card rounded-10 bg-white border-0 mb-5">
                          <div className="card-body p-5">
                            <h4 className="mb-4">Change My Password</h4>
                            <div className="row g-3 mb-4 text-start">
                              <div className="col-12 col-sm-6">
                                <InputField
                                  placeholder="New Password *"
                                  type="password"
                                  name="new_password"
                                  value={formData.new_password}
                                  onChange={handleChange}
                                  customClass={
                                    errors.new_password ? "is-invalid" : ""
                                  }
                                />
                                {errors.new_password && (
                                  <div className="text-danger font-13">
                                    {errors.new_password}
                                  </div>
                                )}
                              </div>
                              <div className="col-12 col-sm-6">
                                <InputField
                                  placeholder="confirm Password *"
                                  type="password"
                                  name="confirm_password"
                                  value={formData.confirm_password}
                                  onChange={handleChange}
                                  customClass={
                                    errors.confirm_password ? "is-invalid" : ""
                                  }
                                />
                                {errors.confirm_password && (
                                  <div className="text-danger font-13">
                                    {errors.confirm_password}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="row g-3 text-end">
                              <div className="col-12 col-sm-12">
                                <button
                                  type="submit"
                                  className="btn btn-primary btn-lg ps-5 pe-5 font-15 mb-3"
                                >
                                  Save
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Password;
