import React, {useState, useEffect} from "react";
import RedirectToHome from "../components/RedirectToHome";
import RedirectToSignin from "../components/RedirectToSignin";
import {useDispatch, useSelector} from "react-redux";
import Footer from "../components/Footer";
import BannerImg from "../assets/images/New-Banner 1.png";
import SecondaryHeader from "../components/SecondaryHeader";
import PrimaryMobileHeader from "../components/PrimaryMobileHeader";
import frontView from "../assets/images/toyota GR supra 2025.png";
import sideView from "../assets/images/toyota GR86 2024.png";
import backView from "../assets/images/toyota grand highlander 2024.png";
import innerSideView from "../assets/images/toyota highlander hybrid 2024.png";
import innerBackView from "../assets/images/toyota prius prime 2024.png";
import cockpitView from "../assets/images/toyota mirai 2024.png";
import serviceKitImg from "../assets/images/ss4.png";
import maintenanceKitImg from "../assets/images/Frame 894.png";
import accessoriesImg from "../assets/images/trade2.png";
import {Worker, Viewer} from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import {
    signOutAction,
    getCarBySlug,
    getCarModel,
    bookCar,
} from "../redux/auth/authActions";
import {API_URL} from "../api/authApi";
import {useLocation, useNavigate} from "react-router-dom";
import {saveAs} from "file-saver";
import Swal from "sweetalert2";
import {Modal} from "react-bootstrap"; // Using Bootstrap modal for simplicity
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import {Navigation, Thumbs} from "swiper/modules";
import {useTranslation} from "react-i18next";
import bannerPlaceHolder from "../assets/images/Hero-Banner-Placeholder-Light-.png";
import "../App.css";
import i18n from "../i18n";
import BgForBrandModels from "../components/BgForBrandModels";
import {RotatingLines} from "react-loader-spinner";
import loaderLogo from "../assets/images/CM LOGO DARK BLUE.png";
import {Helmet} from 'react-helmet';
import ImagePreviewModal from "../components/car-specification/image-preview.modal";
import JSZip from "jszip";
import axios from 'axios';
import {
    Tooltip,
} from 'react-tippy';
import 'react-tippy/dist/tippy.css'
import 'tippy.js/dist/tippy.css';
import Tippy from "@tippyjs/react";

const CarSpecification = () => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const [isMobile, setIsMobile] = useState(false);
    const [pdfUrl, setPdfUrl] = useState(null);
    const [car, setCar] = useState([]);
    const api_url = API_URL.replace("/api", "");
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    // const [carModel, setCarModel] = useState([]);
    const {id, name} = location.state || {};
    const searchParams = new URLSearchParams(location?.search);
    const [downloadLoading, setDownloadLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const [hoveredIdx, setHoveredIdx] = useState(null);
    const [backgroundLogo, setBackgroundLogo] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const {isAuthenticated, user} = useSelector((state) => state.auth);
    const userData = user?.data?.user || {};
    const slug = searchParams?.get("car");
    const [isLuxury, setIsLuxury] = useState(false);
    const fetchCarBySlug = async (slug) => {
        setIsLoading(true);
        const response = await dispatch(getCarBySlug(slug));
        setCar(response?.data);
        const pdfUrl = response?.data?.attachments?.[0]?.file?.path
            ? `${api_url}/${response?.data?.attachments?.[0]?.file?.path}`
            : null;

        setPdfUrl(pdfUrl);
        setBackgroundLogo(
            `${api_url}/${response?.data?.car_make?.background_logo?.path}`
        );
        setIsLuxury(response?.data?.car_make?.is_luxury || false);
        setIsLoading(false);
        //for testing purpose only
        //  setPdfUrl("/prado%20spec%20sheet-compressed%201.pdf");
    };
    const downloadPdf = (url) => {
        const link = document.createElement("a");
        link.href = url;
        link.download = "document.pdf"; // Specify a default file name
        link.target = "_blank"; // Opens the link in a new tab to handle download correctly on some browsers
        document.body.appendChild(link); // Append link to the body
        link.click(); // Trigger the download
        document.body.removeChild(link); // Clean up by removing the link
    };

    const viewOrder = [
        "Front View",
        "Side View",
        "Back View",
        "Inner Side View",
        "Inner Back Side View",
        "Inner Front Side View",
        "Inner Front View",
        "Drive View",
    ];
    const orderedImages = car?.images?.filter(
        (item) => item.name !== "Banner" && item.name !== "Thumbnail"
    );
    // .map((viewName) => car?.images?.find((image) => image?.name === viewName))
    // .filter((image) => !!image);
    // const fetchCarModel = async (id) => {
    //   const response = await dispatch(getCarModel(id));
    //   setCarModel(response?.data);
    // };
    const handleImageClick = (index) => {
        setActiveIndex(index);
        setIsModalOpen(true); // Show the modal
    };
    const handleBookCar = (id) => {
        Swal.fire({
            title: `${t("booking_pop_6")}`,
            text: `${t("booking_pop_7")}`,
            icon: "info", // More friendly icon
            showCancelButton: true,
            confirmButtonColor: "#000740", // Custom primary color
            cancelButtonColor: "#d33",
            confirmButtonText: `${t("book_confirm")}`,
            cancelButtonText: `${t("book_cancel")}`,
            background: "#f7f9fc", // Light background
        }).then(async (result) => {
            if (result.isConfirmed) {
                const response = await dispatch(bookCar(id));
                // if(response?.data)
                // Action to be taken after confirmation
                if (response?.code === 200) {
                    const refId = response?.data?.code;
                    const email = userData?.email;

                    Swal.fire({
                        title: `${t("booked")}`,
                        html: `<p>${t("booking_pop_1")}<strong>${email}</strong>.</p>
                   <p>${t("booking_pop_2")}<strong>${refId}</strong> ${t(
                            "booking_pop_3"
                        )}</p>
                   <p>${t("booking_pop_4")}</p>
                   <p><strong>${t("booking_pop_5")}</strong></p>`,
                        icon: "success",
                        confirmButtonText: `${t("Go To Modification")}`, // Custom button text
                        confirmButtonColor: "#000740", // Custom primary color
                        timer: 5000, // 5 seconds auto-dismiss
                        timerProgressBar: true, // Show timer progress bar
                        willClose: () => {
                            navigate(`/modifications?car=${car?.slug}`, {
                                state: {slug: car?.slug},
                            }); // Auto navigate when Swal closes
                        },
                    }).then((result) => {
                        if (result.isConfirmed) {
                            navigate(`/modifications?car=${car?.slug}`, {
                                state: {slug: car?.slug},
                            }); // Navigate when the user clicks the button
                        }
                    });
                }
            }
        });
    };
    const handleMouseEnter = (idx) => {
        setHoveredIdx(idx);
    };
    const setNameCar = () => {
        const {
            steering_type,
            name,
            model,
            engine_size,
            fuel_type,
            transmission,
            ext_color,
            production_year,
        } = car || {};
        const transmissionLabel = car?.transmission === "Automatic" ? "AT" : "ML";
        const carName = `${steering_type ? `(${steering_type})` : ""} ${
            name ? name : ""
        } ${model ? model : ""} ${engine_size ? engine_size : ""} ${
            fuel_type ? fuel_type : ""
        } ${transmissionLabel ? transmissionLabel : ""} ${
            ext_color ? ext_color : ""
        } - ${production_year ? `MY${production_year}` : ""}`;
        return carName.trim();
    };

    const handleMouseLeave = () => {
        setHoveredIdx(null);
    };
    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 600);
        window.addEventListener("resize", handleResize);
        handleResize();
        fetchCarBySlug(slug);
        if (!isModalOpen) {
            setThumbsSwiper(null); // Reset the thumbs swiper when modal is closed
        }
        const detectedLanguage =
            localStorage.getItem("selectedLanguage") ||
            navigator.language.split("-")[0] ||
            "en";
        i18n.changeLanguage(detectedLanguage);
        document.documentElement.lang = detectedLanguage;
        // fetchCarModel(id);
        // Generate PDF on component mount

        return () => window.removeEventListener("resize", handleResize);
    }, [location.search]);

    const getBannerImage = () => {
        const bannerImage = car?.images?.find((item) => item.name === "Banner");
        if (bannerImage) {
            return `${api_url}/${bannerImage?.image?.path}`; // Construct the full path
        } else {
            return bannerPlaceHolder; // Return placeholder image if no banner found
        }
    };

    const goToModification = () => {
        navigate("/modifications", {state: {slug: slug}});
    };

    useEffect(() => {
        if (!isModalOpen) {
            setThumbsSwiper(null);
        }
    }, [isModalOpen]);
    const carTitle = `${car?.name} ${car?.production_year} - Cruise Motors`;
    const carDescription = `Explore the ${car?.production_year} ${car?.name} -brand:${car?.car_make?.name}, model:${car?.car_model?.name}, features:${car?.engine_size}, ${car?.transmission}, ${car?.steering_type}, ${car?.fuel_type}, ${car?.ext_color}, ${car?.int_color}, ${car?.production_year}at Cruise Motors.`;

    const carData = {
        "@context": "http://schema.org",
        "@type": "Car",
        "name": carTitle,
        "description": carDescription,
        "brand": car?.make,
        "model": car?.model,
        "modelDate": car?.production_year,
        "offers": {
            "@type": "Offer",
            "priceCurrency": "AED",
            "price": car?.price,
            "availability": "http://schema.org/InStock",
            "seller": {
                "@type": "AutoDealer",
                "name": "Cruise Motors"
            }
        }
    };

    const handleDownload = async () => {
        setDownloadLoading(true)
        try {
            const response = await axios.post(
                `${API_URL}/download-images`,
                {carTitle: carTitle, images: orderedImages},
                {responseType: 'blob'} // Important for handling binary data
            );

            // Create a download link for the ZIP file
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${carTitle}.zip`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setDownloadLoading(false)
        } catch (error) {
            setDownloadLoading(false)
            console.error('Error downloading images:', error);
        }
    };

    return (

        <>
            <Helmet>
                <title>{carTitle}</title>
                <meta name="robots" content="noindex"/>
                <meta name="description" content={carDescription}/>
                <meta property="og:title" content={carTitle}/>
                <meta property="og:description" content={carDescription}/>
                <meta property="og:type" content="website"/>
                <meta property="og:url" content={window.location.href}/>
                <meta name="twitter:card" content="summary_large_image"/>
                <meta name="twitter:title" content={carTitle}/>
                <meta name="twitter:description" content={carDescription}/>
                <script type="application/ld+json">{JSON.stringify(carData)}</script>
            </Helmet>

            <div className={`content-wrapper ${isModalOpen ? "blurred" : ""}`}>
                <BgForBrandModels url={backgroundLogo} isBlack={isLuxury}/>
                <RedirectToSignin/>
                <RedirectToHome/>
                {!isMobile ? (
                    <SecondaryHeader isBlack={isLuxury}/>
                ) : (
                    <PrimaryMobileHeader/>
                )}

                {isLoading ? (
                    <div className="d-flex justify-content-center align-items-center vh-100">
                        <RotatingLines
                            strokeColor="#000740"
                            strokeWidth="4"
                            animationDuration="0.75"
                            width="96"
                            visible={true}
                        />
                        <img
                            src={loaderLogo}
                            style={{width: "250px", height: "250px"}}
                            alt="Loading"
                        />
                    </div>
                ) : (
                    <div className={`container ${isLuxury ? "luxury-product" : ""}`}>
                        <div className="text-center pt-3 pb-3">
                            <img
                                src={getBannerImage()}
                                alt="Logo"
                                className="shop-image img-fluid" // Use img-fluid for responsive image
                                style={{maxWidth: "100%"}}
                            />

                            {/* Ensure the h3 and button are responsive */}
                            <div
                                className={`d-flex flex-wrap mt-3 justify-content-between align-items-center ${
                                    isLuxury ? "luxury-product" : ""
                                }`}
                            >
                                <h3 className="text-uppercase fw-bold col-12 col-md-10 text-md-start mb-3 mb-md-0 car-detail-title">
                                    {car?.name}
                                </h3>
                                <button
                                    type="submit"
                                    className="btn btn-primary btn-lg ps-4 pe-4 font-15 mb-3 col-12 col-md-2"
                                >
                                    {car?.code}
                                </button>
                            </div>
                        </div>

                        {/* Images Grid */}
                        <div>
                            <div className="d-flex justify-content-sm-end">
                                <a href="#" onClick={handleDownload}>
                                    {downloadLoading ? 'Downloading...' : 'Download Images'}
                                </a>
                            </div>
                            <div className="row text-center">
                                {orderedImages?.map((image, idx) => (
                                    <div className="col-12 col-sm-4 col-md-4 mb-3" key={idx}>
                                        <div className="position-relative">
                                            <img
                                                src={`${api_url}/${image?.image?.path}`}
                                                alt={image?.name}
                                                className="img-fluid rounded"
                                                style={{
                                                    objectFit: "cover",
                                                    width: "100%",
                                                    height: "auto",
                                                }}
                                                onClick={() => handleImageClick(idx)}
                                            />
                                            <div
                                                className="position-absolute"
                                                style={{
                                                    width: "30px",
                                                    height: "28px",
                                                    bottom: "1px",
                                                    left: "5px",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <Tippy content={image.name} placement="bottom">
                                                    <i
                                                        className="bi bi-info-circle font-20"
                                                        style={{ color: "white", cursor: "pointer" }}
                                                    ></i>
                                                </Tippy>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>

                        </div>

                        {/* PDF and Product List Section */}
                        <div className="row mt-5 mb-5">
                            <div
                                className=" d-flex justify-content-center"
                                style={{height: "1000px"}}
                            >
                                <div className="">
                                    <div className="pdf-section">
                                        <div
                                            className="pdf-viewer"
                                            style={{
                                                height: "820px",
                                                border: "1px solid rgba(0, 0, 0, 0.3)",
                                                position: "relative",
                                            }}
                                        >
                                            {/* Check if the PDF URL exists */}
                                            {pdfUrl ? (
                                                <iframe
                                                    src={pdfUrl}
                                                    width="700px"
                                                    height="1000px"
                                                    style={{border: "none"}}
                                                    title="Specification Sheet"
                                                />
                                            ) : (
                                                <p className="text-center pt-5">{t("no_pdf")}</p>
                                            )}

                                            {pdfUrl && (
                                                <button
                                                    className="btn btn-primary"
                                                    style={{
                                                        backgroundColor: "#000740",
                                                        borderRadius: "8px",
                                                        position: "absolute",
                                                        bottom: "-123px", // Stick to the bottom of the pdf-viewer
                                                        left: "50%", // Center it horizontally
                                                        transform: "translateX(-50%)", // Adjust for centering
                                                        width: "60%", // Adjust the width as needed
                                                    }}
                                                    onClick={() => downloadPdf(pdfUrl)}
                                                >
                                                    {t("download_pdf")}
                                                </button>
                                            )}
                                        </div>

                                        {/* Optional Download Button */}
                                        {/* {pdfUrl && (
                <button
                  className="btn btn-primary mt-3 w-100"
                  style={{ backgroundColor: "#4e3DBD", borderRadius: "8px" }}
                  onClick={() => downloadPdf(pdfUrl)}
                >
                  Download PDF
                </button>
              )} */}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-1"></div>

                            {/* Product List */}
                            <div className="col-md-5 mt-2">
                                {/* {[
              {
                image: serviceKitImg,
                name: "Toyota Service Kit",
                short_description: "4.0 D5 PowerPulse Momentum 5dr AW",
                price: "125 AED",
              },
              {
                image: maintenanceKitImg,
                name: "Toyota Maintenance Kit",
                short_description: "4.0 D5 PowerPulse Momentum 5dr AW",
                price: "125 AED",
              },
              {
                image: accessoriesImg,
                name: "Toyota Accessories",
                short_description: "4.0 D5 PowerPulse Momentum 5dr AW",
                price: "125 AED",
              },
            ].map((item, idx) => (
              <div
                className="card shadow-sm p-3 mb-4 bg-white rounded"
                key={idx}
              >
                <div className="d-flex align-items-center">
                  <img
                    src={item.image}
                    alt={item.name}
                    className="img-fluid rounded"
                    style={{ width: "100px", marginRight: "15px" }}
                  />
                  <div>
                    <h6 className="fw-bold">{item?.name}</h6>
                    <span>{item?.short_description}</span>
                    <hr />
                    <div className="d-flex justify-content-between align-items-baseline">
                      <p>{item?.price}</p>
                      <button className="btn btn-primary">
                        {t("add_to_cart")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))} */}
                            </div>
                        </div>
                        <div className="col-12 d-flex justify-content-center mt-5 mb-5">
                            {!car?.is_booked ? (
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => handleBookCar(car?.id)}
                                >
                                    {t("book")}
                                </button>
                            ) : (
                                <>
                                    <button type="button" className="btn btn-primary">
                                        {t("booked")}: {car?.booked_code}
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-primary ms-3"
                                        onClick={goToModification}
                                    >
                                        Modify This Car
                                    </button>
                                </>
                            )}
                        </div>
                        <ImagePreviewModal
                            isModalOpen={isModalOpen}
                            setIsModalOpen={setIsModalOpen}
                            setThumbsSwiper={setThumbsSwiper}
                            activeIndex={activeIndex}
                            setActiveIndex={setActiveIndex}
                            thumbsSwiper={thumbsSwiper}
                            orderedImages={orderedImages}
                            api_url={api_url}
                        />
                    </div>
                )}
                <Footer isBlack={isLuxury}/>
            </div>
        </>
    );
};


export default CarSpecification;
